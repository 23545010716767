import { createSlice } from "@reduxjs/toolkit";

export const utilitySlice = createSlice({
  name: "utility",
  initialState: {
    device: "desktop",
  },
  reducers: {
    setDeviceMobile: (state) => {
      state.device = "mobile";
    },
    setDeviceDesktop: (state) => {
      state.device = "desktop";
    },
  },
});

export const { setDeviceDesktop, setDeviceMobile } = utilitySlice.actions;

export default utilitySlice.reducer;
