import React, { useEffect, useState } from "react";
import {
  fetchArtist,
  fetchArtistAlbums,
  fetchTrackFeatures,
} from "./functions/spotify-fetches";
import "./theme/spotifyPage.css";
import "./theme/interaction.css";
import { ArtistInfo } from "./components/ArtistInfo";
import { useSelector } from "react-redux";
import SingleItemSearchBar from "./components/SingleItemSearchBar";
import DualSearch from "./components/DualSearch";

const ArtistSearch = () => {
  const fetchError = useSelector((state) => state.result.fetchError);
  const deviceMode = useSelector((state) => state.utility.device);

  const [searchURL, setSearchURL] = useState("");
  const [urlInput, setUrlInput] = useState("");
  const artistState = useSelector((state) => state.result.artistMeta);

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  const handleSearch = () => {
    setSearchURL(urlInput);
  };

  const handleSampleA = async () => {
    let sampleURL =
      "https://open.spotify.com/artist/2UOVgpgiNTC6KK0vSC77aD?si=c29a6cc230d8437c";

    setSearchURL(sampleURL);
  };

  const handleSampleB = async () => {
    let sampleURL =
      "https://open.spotify.com/artist/0C8ZW7ezQVs4URX5aX7Kqx?si=lySjIv8vTAilMXADPyCX4Q";

    setSearchURL(sampleURL);
  };

  const handleSampleC = async () => {
    let sampleURL =
      "https://open.spotify.com/artist/7aHNy2bjgGqOeFqUZ1shgb?si=67ca11a46d694efe";
    setSearchURL(sampleURL);
  };

  useEffect(() => {
    fetchArtistAlbums(artistState.id);
  }, [artistState]);

  return (
    <>
      <div className={"content ".concat(deviceMode)}>
        {/* <SingleItemSearchBar /> */}
        <DualSearch />
        <ArtistInfo search={searchURL} />
      </div>
    </>
  );
};

export default ArtistSearch;
