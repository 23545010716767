import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import "../theme/trackInformation.css";
import "../theme/displayParts.css";
import IdTag from "./IdTag";

export const TrackMetaCard = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);

  const trackInfo = useSelector((state) => state.result.trackMeta);

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (trackInfo.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [trackInfo]);

  function getImageIndex() {
    if (trackInfo.album.images.length > 1) {
      return 1;
    } else {
      return 0;
    }
  }

  function getArtistNames() {
    var artistNames = [];
    for (let i = 0; i < trackInfo.album.artists.length; i++) {
      artistNames.push(trackInfo.album.artists[i].name);
    }
    return artistNames.join(", ");
  }

  function getYear() {
    const yearRegex = /[0-9]{4}/;
    const yearMatch = yearRegex.exec(trackInfo.album.release_date);
    if (yearMatch != null) {
      return yearMatch[0];
    } else {
      return "";
    }
  }

  return (
    <div className={"section meta-card ".concat(deviceMode)}>
      <div className="meta-image">
        <a href={trackInfo.external_urls.spotify} target="_blank">
          <img src={trackInfo["album"].images[getImageIndex()].url}></img>
        </a>
      </div>
      <p>{trackInfo.name}</p>
      <p>{getArtistNames()}</p>
      <IdTag tag={trackInfo.id} type={"track"} lookup={false} />
      <p>
        <em>
          {trackInfo.album.name} ({getYear()})
        </em>
      </p>
    </div>
  );
};

export const TrackDetailCard = () => {
  const deviceMode = useSelector((state) => state.utility.device);

  const trackInfo = useSelector((state) => state.result.trackMeta);

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (trackInfo.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [trackInfo]);

  function getKey(keyVal) {
    if (typeof keyVal != "number") {
      return;
    }
    switch (keyVal) {
      case 0:
        return "C";
      case 1:
        return "C♯/B♭";
      case 2:
        return "D";
      case 3:
        return "D♯/E♭";
      case 4:
        return "E";
      case 5:
        return "F";
      case 6:
        return "F♯/G♭";
      case 7:
        return "G";
      case 8:
        return "G♯/A♭";
      case 9:
        return "A";
      case 10:
        return "A♯/B♭";
      case 11:
        return "B";
      default:
        return "Unknown";
    }
  }

  function getDurationStr() {
    var minutes = Math.floor(trackInfo.duration_ms / 60000);
    var seconds = ((trackInfo.duration_ms % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  return (
    <div
      className={"section ".concat(deviceMode)}
      style={hide ? { display: "none" } : {}}
    >
      <div className="track-data detail-data">
        <h2>Track Details</h2>
        <p>Duration: {getDurationStr()}</p>
        <p>Track Number: {trackInfo.track_number}</p>
        <p>Popularity: {trackInfo.popularity}/100</p>
        <p>Explicit: {trackInfo.explicit ? "Yes" : "No"}</p>
        <p>External IDs:</p>
        <ul>
          {typeof trackInfo.external_ids.isrc == "undefined" ? (
            <></>
          ) : (
            <li>ISRC: {trackInfo.external_ids.isrc}</li>
          )}
          {typeof trackInfo.external_ids.ean == "undefined" ? (
            <></>
          ) : (
            <li>EAN: {trackInfo.external_ids.ean}</li>
          )}
          {typeof trackInfo.external_ids.upc == "undefined" ? (
            <></>
          ) : (
            <li>UPC: {trackInfo.external_ids.upc}</li>
          )}
        </ul>
        <h2>Related ID Numbers</h2>
        <div>
          <p>Track ID:</p>
          <IdTag tag={trackInfo.id} type={"track"} lookup={false} />
        </div>
        <div>
          <p>Album ID:</p>
          <IdTag tag={trackInfo.album.id} type={"album"} />
        </div>
        <p>Artist IDs:</p>
        <ul>
          {trackInfo.artists.map((key) => {
            return (
              <li key={key.id}>
                <p>{key.name}</p> <IdTag tag={key.id} type={"artist"} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export const TrackFeatureCard = () => {
  const deviceMode = useSelector((state) => state.utility.device);

  const trackFeatureState = useSelector((state) => state.result.trackFeatures);

  const trackInfo = useSelector((state) => state.result.trackMeta);

  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (trackInfo.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [trackInfo]);

  function getKey(keyVal) {
    if (typeof keyVal != "number") {
      return;
    }
    switch (keyVal) {
      case 0:
        return "C";
      case 1:
        return "C♯/B♭";
      case 2:
        return "D";
      case 3:
        return "D♯/E♭";
      case 4:
        return "E";
      case 5:
        return "F";
      case 6:
        return "F♯/G♭";
      case 7:
        return "G";
      case 8:
        return "G♯/A♭";
      case 9:
        return "A";
      case 10:
        return "A♯/B♭";
      case 11:
        return "B";
      default:
        return "Unknown";
    }
  }

  return (
    <div>
      <div
        className={"section ".concat(deviceMode)}
        style={
          hide
            ? { display: "none" }
            : {
                display: "flex",
                flexDirection: "column",
                alignSelf: "stretch",
                justifySelf: "stretch",
                alignContent: "stretch",
                justifyContent: "stretch",
                justifyItems: "stretch",
                alignItems: "stretch",
                flexGrow: 1,
                flexShrink: 1,
              }
        }
      >
        <h2>Technical Features</h2>
        <p>Key: {getKey(trackFeatureState.key)}</p>
        <p>Tempo: {trackFeatureState.tempo}bpm</p>
        <p>
          Time signature (approximate): {trackFeatureState.time_signature}/4
        </p>
        <p>Loudness: {trackFeatureState.loudness}dB</p>
      </div>
    </div>
  );
};
