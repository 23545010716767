import React, { useEffect, useState } from "react";
import {
  fetchTrackInfo,
  fetchTrackFeatures,
  fetchAlbum,
  fetchMultipleTrackFeatures,
} from "./functions/spotify-fetches";
import TrackRadarChart from "./components/TrackRadarChart";
import { AlbumBasicData, AlbumDetailsCard } from "./components/AlbumInfoCards";
import { useDispatch, useSelector } from "react-redux";
import { updateFetchError } from "./redux/resultSlice";
import QuickTrackList from "./components/QuickTrackList";
import {
  getMedianChart,
  getMultiTracksColor,
  getTrackColor,
} from "./functions/spotify-utility";
import SingleItemSearchBar from "./components/SingleItemSearchBar";
import DualSearch from "./components/DualSearch";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  // speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

export const AlbumSearch = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);

  const albumState = useSelector((state) => state.result.albumMeta);
  const albumFeatures = useSelector((state) => state.result.albumFeatures);
  const fetchError = useSelector((state) => state.result.fetchError);
  const dispatch = useDispatch();
  const [urlInput, setUrlInput] = useState("");

  const [collectiveRadarData, setCollectiveRadarData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        // speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000" },
    },
  ]);

  const [averageRadarData, setAverageRadarData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        // speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000" },
    },
  ]);

  const [trackRadarData, setTrackRadarData] = useState([
    [
      {
        data: {
          energy: 0.0,
          acousticness: 0.0,
          danceability: 0.0,
          liveness: 0.0,
          instrumentalness: 0.0,
          // speechiness: 0.0,
          valence: 0.0,
        },
        meta: { color: "#000" },
        index: 0,
      },
    ],
  ]);
  const [lastRequest, setLastRequest] = useState("");

  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (albumState.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [albumState]);

  const handleSearch = () => {
    if (lastRequest == urlInput || urlInput.length < 1) {
      return;
    }
    if (urlInput.length > 0) {
      setLastRequest(urlInput);
      fetchAlbum(urlInput);
    }
  };

  function handleMulitpleFeaturesFetch() {
    var allIDs = [];
    var tracksList = albumState.tracks.items;
    for (let i = 0; i < tracksList.length; i++) {
      allIDs.push(tracksList[i].id);
    }
    fetchMultipleTrackFeatures(allIDs);
  }

  const [aveColour, setAveColour] = useState("#fff0");

  function createRadarChart() {
    var features = albumFeatures.audio_features;
    if (typeof features == "undefined") {
      return;
    }

    var perTrackRadarData = [];

    var multiRadarData = [];
    var averageFeatures = {
      acousticness: 0.0,
      analysis_url: "",
      danceability: 0.0,
      duration_ms: 0,
      energy: 0.0,
      id: "",
      instrumentalness: 0.0,
      key: -1,
      liveness: 0.0,
      loudness: -0.0,
      mode: -1,
      speechiness: 0.0,
      tempo: 0.0,
      time_signature: 0,
      track_href: "",
      type: "",
      uri: "",
      valence: 0.0,
    };
    var validCount = 0.0;

    for (let i = 0; i < features.length; i++) {
      if (features[i] != null) {
        multiRadarData.push({
          data: {
            energy: features[i].energy,
            acousticness: features[i].acousticness,
            danceability: features[i].danceability,
            liveness: features[i].liveness,
            instrumentalness: features[i].instrumentalness,
            speechiness: features[i].speechiness,
            valence: features[i].valence,
          },
          meta: {
            color: getTrackColor(features[i]),
          },
        });
        perTrackRadarData.push([
          {
            data: {
              energy: features[i].energy,
              acousticness: features[i].acousticness,
              danceability: features[i].danceability,
              liveness: features[i].liveness,
              instrumentalness: features[i].instrumentalness,
              speechiness: features[i].speechiness,
              valence: features[i].valence,
            },
            meta: {
              color: getTrackColor(features[i]),
            },
            index: i,
          },
        ]);
      } else {
        perTrackRadarData.push([
          {
            data: {
              energy: 0.0,
              acousticness: 0.0,
              danceability: 0.0,
              liveness: 0.0,
              instrumentalness: 0.0,
              speechiness: 0.0,
              valence: 0.0,
            },
            meta: {
              color: "#0000",
            },
            index: i,
          },
        ]);
      }
    }

    setCollectiveRadarData(multiRadarData);
    setAverageRadarData(getMedianChart(multiRadarData));

    let averageColor = getMultiTracksColor(multiRadarData);
    setAveColour(averageColor);

    setTrackRadarData(perTrackRadarData);
  }

  useEffect(() => {
    createRadarChart();
  }, [albumFeatures]);

  useEffect(() => {
    console.log(aveColour);
  }, [aveColour]);

  useEffect(() => {
    handleMulitpleFeaturesFetch();
  }, [albumState]);

  useEffect(() => {
    dispatch(updateFetchError({ message: "", mode: "" }));
  }, []);

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  function handleSampleA() {
    setUrlInput(
      "https://open.spotify.com/album/5bfpRtBW7RNRdsm3tRyl3R?si=h68akCRhQEyfdoAmQ4kJpw"
    );
    handleSearch();
  }

  function handleSampleB() {
    setUrlInput(
      "https://open.spotify.com/album/6kZ42qRrzov54LcAk4onW9?si=zOg5ZjDPTlu-t_lgAyP3uQ"
    );
    handleSearch();
  }

  return (
    <div className={"content ".concat(deviceMode)}>
      <div className="grid-chunk">
        {/* <SingleItemSearchBar /> */}
        <DualSearch />
        {albumState.id == "" ? (
          <></>
        ) : (
          <AlbumBasicData albumdata={albumState} hide={false} />
        )}
      </div>
      {albumState.id == "" ? (
        <></>
      ) : (
        <div className={"section ".concat(deviceMode)}>
          <TrackRadarChart
            radardata={collectiveRadarData}
            labels={radarLabels}
            hide={false}
            title={"All Album Characteristics"}
          />
        </div>
      )}
      {albumState.id == "" ? (
        <></>
      ) : (
        <div className={"section ".concat(deviceMode)}>
          <TrackRadarChart
            radardata={averageRadarData}
            labels={radarLabels}
            hide={false}
            mode={"average"}
            title={"Average Album Characteristics"}
          />
        </div>
      )}

      {albumState.id == "" ? (
        <></>
      ) : (
        <AlbumDetailsCard albumdata={albumState} hide={true} />
      )}
    </div>
  );
};
