import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    mode: "track",
    lastID: "",
    currentID: "",
    lastResult: {},
  },
  reducers: {
    updateMode: (state, action) => {
      state.mode = action.payload;
    },
    updateID: (state, action) => {
      state.lastID = state.currentID;
      state.currentID = action.payload;
    },
    updateResult: (state, action) => {
      state.lastResult = action.payload;
    },
  },
});

export const { updateMode, updateID } = searchSlice.actions;

export default searchSlice.reducer;
