import { configureStore } from "@reduxjs/toolkit";
import tokenReducer from "./redux/tokenSlice"
import searchReducer from "./redux/searchSlice"
import resultReducer from "./redux/resultSlice"
import recommendationReducer from "./redux/recommendationSlice";
import userReducer from "./redux/userSlice"
import utilityReducer from "./redux/utility"

const spotifyStore = configureStore({
  reducer: {
    token: tokenReducer,
    search: searchReducer,
    result: resultReducer,
    recommendation: recommendationReducer,
    user: userReducer,
    utility: utilityReducer,
  },
});

export default spotifyStore;
