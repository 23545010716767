import spotifyStore from "../store";
import { updateCurrentlyPlaying, updateFollowing, updateProfile, updateTopArtists, updateTopTracks } from "../redux/userSlice";

export async function fetchUserProfile() {
  const accessToken = localStorage.getItem("access_token");

  let profileData;
  let fetchResponse;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (fetchResponse.ok) {
      profileData = await fetchResponse.json();

    } else {
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    console.log(error);
  }

  if (profileData != null) {
    console.log(profileData);
    spotifyStore.dispatch(updateProfile(profileData));
  }


}


export async function fetchCurrentlyPlaying() {
  const accessToken = localStorage.getItem("access_token");

  let trackInfo;
  let fetchResponse;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/me/player/currently-playing", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (fetchResponse.ok) {
      trackInfo = await fetchResponse.json(); spotifyStore.dispatch(updateCurrentlyPlaying(trackInfo));
      console.log(trackInfo);
    } else {
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    console.log(error);
  }
}


export async function fetchTopUserTracks(timeRange) {

  const accessToken = localStorage.getItem("access_token");

  let tracksData;
  let fetchResponse;


  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/me/top/tracks?time_range=" + timeRange + "&limit=20", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (fetchResponse.ok) {
      tracksData = await fetchResponse.json();
      spotifyStore.dispatch(updateTopTracks(tracksData));

    } else {
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    console.log(error);
  }

}

export async function fetchTopUserArtists(timeRange) {

  const accessToken = localStorage.getItem("access_token");

  let artistData;
  let fetchResponse;


  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/me/top/artists?time_range=" + timeRange, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (fetchResponse.ok) {
      artistData = await fetchResponse.json();
      spotifyStore.dispatch(updateTopArtists(artistData));
      console.log(artistData);

    } else {
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    console.log(error);
  }

}



export async function checkUserFollowing(artistIDs) {
  const accessToken = localStorage.getItem("access_token");

  let artistData;
  let fetchResponse;


  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/me/following/contains?type=artist&ids=" + encodeURIComponent(artistIDs.join(",")), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    });

    if (fetchResponse.ok) {
      artistData = await fetchResponse.json();
    } else {
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    console.log(error);
  }

  return artistData;
}