import React, { useEffect, useState } from "react";

import { getTrackColor } from "../functions/spotify-utility";
import {
  checkUserFollowing,
  fetchTopUserArtists,
  fetchTopUserTracks,
  fetchUserFollowing,
} from "../functions/user-fetches";
import { useDispatch, useSelector } from "react-redux";
import { updateMode } from "../redux/searchSlice";
import { fetchMultipleTrackFeatures } from "../functions/spotify-fetches";
import TrackRadarChart from "../components/TrackRadarChart";
import "../theme/userPage.css";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  // speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

const UserArtistsGenres = () => {
  const dispatch = useDispatch();
  const topArtists = useSelector((state) => state.user.topArtists);
  const following = useSelector((state) => state.user.following);
  const deviceMode = useSelector((state) => state.utility.device);

  const [showTopTracks, setShowTopTracks] = useState(true);
  const [timeRange, setTimeRange] = useState("medium_term");
  const [topGenres, setTopGenres] = useState([]);
  const [genreFrequencies, setGenreFrequencies] = useState([]);

  async function changeTimeRange(term) {
    if (term != timeRange) {
      await fetchTopUserArtists(term);
      setTimeRange(term);
    }
  }

  function processTopGenres() {
    let genresList = [];
    topArtists.items.forEach((artist) => {
      artist.genres.forEach((genre) => {
        if (genresList.indexOf(genre) < 0) {
          genresList.push(genre);
        }
      });
    });
    countTopGenres(genresList);
    setTopGenres(genresList);
  }

  function countTopGenres(genres) {
    let genreCount = Array.apply(null, Array(genres.length)).map(function (
      y,
      i
    ) {
      return 0;
    });
    let highestCount = 0;
    let genresLists = topArtists.items.map((x) => x.genres);
    for (let i = 0; i < genresLists.length; i++) {
      for (let j = 0; j < genresLists[i].length; j++) {
        genreCount[genres.indexOf(genresLists[i][j])]++;
        if (genreCount[genres.indexOf(genresLists[i][j])] > highestCount) {
          highestCount = genreCount[genres.indexOf(genresLists[i][j])];
        }
      }
    }

    let frequencies = genreCount.map((x) => x / highestCount);
    setGenreFrequencies(frequencies);
  }

  useEffect(() => {
    if (topArtists.items[0].id == "") {
      fetchTopUserArtists(timeRange);
    }
  }, []);

  useEffect(() => {
    console.log(topArtists.items);
    processTopGenres(topArtists);
  }, [topArtists]);

  return (
    <div className={"section user-artists ".concat(deviceMode)}>
      <h2>For the Sake of Art(ists)</h2>
      <div className="user-artists-content">
        <p>Want a different time range?</p>
        {timeRange != "short_term" ? (
          <button onClick={() => changeTimeRange("short_term")}>
            Last 4 weeks
          </button>
        ) : (
          <></>
        )}
        {timeRange != "medium_term" ? (
          <button onClick={() => changeTimeRange("medium_term")}>
            Last 6 months
          </button>
        ) : (
          <></>
        )}
        {timeRange != "long_term" ? (
          <button onClick={() => changeTimeRange("long_term")}>
            Account lifetime
          </button>
        ) : (
          <></>
        )}
      </div>
      <div className="list-part">
        <p>
          Based off of your top 20 artists{" "}
          <em style={{ fontStyle: "normal", fontWeight: 700 }}>
            {timeRange == "short_term"
              ? "from the last 4 weeks"
              : timeRange == "medium_term"
              ? "from the last 6 months"
              : "of all time (on Spotify)"}
          </em>
        </p>
      </div>
      <div className="user-list">
        <div className="item">
          <h3>Your Top Artists</h3>
          <ol>
            {topArtists.items.map((artist) => {
              return (
                <li disabled={true} key={artist.id}>
                  {artist.name}
                </li>
              );
            })}
          </ol>
        </div>
        <div className="item">
          <h3>Your Favourite Genres</h3>
          <div className={"genres-list ".concat(deviceMode)}>
            {topGenres.map((genre) => {
              return (
                <p
                  disabled={true}
                  key={genre}
                  style={{
                    background:
                      "color-mix(in srgb, var(--ternary), var(--secondary) " +
                      genreFrequencies[topGenres.indexOf(genre)] * 100 +
                      "% )",
                  }}
                >
                  {genre}
                </p>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserArtistsGenres;
