import { createSlice } from "@reduxjs/toolkit";

export const resultSlice = createSlice({
  name: "result",
  initialState: {
    availableGenres: [],
    albumMeta: {
      album_type: "",
      total_tracks: 0,
      available_markets: [],
      external_urls: {
        spotify: "",
      },
      href: "",
      id: "",
      images: [
        {
          url: "",
          height: 0,
          width: 0,
        },
      ],
      name: "",
      release_date: "",
      release_date_precision: "",
      restrictions: {
        reason: "",
      },
      type: "",
      uri: "",
      copyrights: [
        {
          text: "",
          type: "",
        },
      ],
      external_ids: {},
      genres: [],
      label: "",
      popularity: 0,
      artists: [
        {
          external_urls: {
            spotify: "",
          },
          followers: {
            href: "",
            total: 0,
          },
          genres: [],
          href: "",
          id: "",
          images: [
            {
              url: "",
              height: 0,
              width: 0,
            },
          ],
          name: "",
          popularity: 0,
          type: "",
          uri: "",
        },
      ],
      tracks: {
        href: "",
        limit: 0,
        next: "",
        offset: 0,
        previous: "",
        total: 0,
        items: [
          {
            artists: [
              {
                external_urls: {
                  spotify: "",
                },
                href: "",
                id: "",
                name: "",
                type: "",
                uri: "",
              },
            ],
            available_markets: [],
            disc_number: 0,
            duration_ms: 0,
            explicit: false,
            external_urls: {
              spotify: "",
            },
            href: "",
            id: "",
            is_playable: false,
            linked_from: {
              external_urls: {
                spotify: "",
              },
              href: "",
              id: "",
              type: "",
              uri: "",
            },
            restrictions: {
              reason: "",
            },
            name: "",
            preview_url: "",
            track_number: 0,
            type: "",
            uri: "",
            is_local: false,
          },
        ],
      },
    },
    trackMeta: {
      album: {
        album_type: "",
        artists: [
          {
            external_urls: {
              spotify: "",
            },
            href: "",
            id: "",
            name: "",
            type: "",
            uri: "",
          },
        ],
        available_markets: [],
        external_urls: {
          spotify: "",
        },
        href: "",
        id: "",
        images: [
          {
            height: 0,
            url: "0",
            width: 0,
          },
        ],
        name: "",
        release_date: "",
        release_date_precision: "",
        total_tracks: 0,
        type: "",
        uri: "",
        label: "",
      },
      album_group: "",
      artists: [
        {
          followers: { href: "", total: 0 },
          external_urls: {
            spotify: "",
          },
          images: [{ url: "", height: 0, width: 0 }],
          popularity: 0,
          href: "",
          id: "",
          name: "",
          type: "",
          uri: "",
        },
      ],
      available_markets: [],
      disc_number: 0,
      duration_ms: 0,
      explicit: false,
      external_ids: {
        isrc: "",
        ean: "",
        upc: "",
      },
      copyights: [{ text: "", type: "" }],
      external_urls: {
        spotify: "",
      },
      href: "",
      id: "",
      is_local: false,
      name: "",
      popularity: 0,
      preview_url: null,
      track_number: 0,
      type: "",
      uri: "",
    },
    albumFeatures: {
      audio_features: [
        {
          acousticness: 0.0,
          analysis_url: "",
          danceability: 0.0,
          duration_ms: 0,
          energy: 0.0,
          id: "",
          instrumentalness: 0.0,
          key: -1,
          liveness: 0.0,
          loudness: -0.0,
          mode: -1,
          speechiness: 0.0,
          tempo: 0.0,
          time_signature: 0,
          track_href: "",
          type: "",
          uri: "",
          valence: 0.0,
        },
      ],
    },
    trackFeatures: {
      acousticness: 0.0,
      analysis_url: "",
      danceability: 0.0,
      duration_ms: 0,
      energy: 0.0,
      id: "",
      instrumentalness: 0.0,
      key: -1,
      liveness: 0.0,
      loudness: -0.0,
      mode: -1,
      speechiness: 0.0,
      tempo: 0.0,
      time_signature: 0,
      track_href: "",
      type: "",
      uri: "",
      valence: 0.0,
    },
    artistMeta: {
      external_urls: {
        spotify: "",
      },
      followers: { href: null, total: 0 },
      genres: [],
      href: "",
      id: "",
      images: [
        {
          url: "",
          height: 0,
          width: 0,
        },
      ],
      name: "",
      popularity: 0,
      type: "0",
      uri: "",
    },
    artistAlbums: {
      href: "",
      limit: 20,
      next: "",
      offset: 0,
      previous: "",
      total: 0,
      items: [
        {
          album_type: "",
          total_tracks: 0,
          available_markets: [],
          external_urls: {
            spotify: "",
          },
          href: "",
          id: "",
          images: [
            {
              url: "",
              height: 0,
              width: 0,
            },
          ],
          name: "",
          release_date: "",
          release_date_precision: "",
          restrictions: {
            reason: "",
          },
          type: "album",
          uri: "",
          copyrights: [
            {
              text: "",
              type: "",
            },
          ],
          external_ids: {
            isrc: "",
            ean: "",
            upc: "",
          },
          genres: [],
          label: "",
          popularity: 0,
          album_group: "",
          artists: [
            {
              external_urls: {
                spotify: "",
              },
              href: "",
              id: "",
              name: "",
              type: "",
              uri: "",
            },
          ],
        },
      ],
    },
    searchResults: {},
    fetchError: { message: "", mode: "" },
    playlistMeta: {
      collaborative: false,
      description: "",
      external_urls: { spotify: "" },
      followers: { href: "", total: 0 },
      href: "",
      id: "",
      images: [
        {
          url: "",
          height: 0,
          width: 0,
        },
      ],
      name: "",
      owner: {
        external_urls: { spotify: "" },
        followers: { href: "", total: 0 },
        href: "",
        id: "",
        type: "",
        uri: "",
        display_name: "",
      },
      public: false,
      snapshot_id: "",
      tracks: {
        href: "",
        limit: 0,
        next: "",
        offset: 0,
        previous: "",
        total: 0,
        items: [
          {
            added_at: "",
            added_by: {
              external_urls: { spotify: "" },
              followers: { href: "", total: 0 },
              href: "",
              id: "",
              type: "",
              uri: "",
            },
            is_local: false,
            track: {
              album: {
                album_type: "",
                total_tracks: 0,
                available_markets: [],
                external_urls: { spotify: "" },
                href: "",
                id: "",
                images: [
                  {
                    url: "",
                    height: 0,
                    width: 0,
                  },
                ],
                name: "",
                release_date: "",
                release_date_precision: "",
                restrictions: { reason: "" },
                type: "",
                uri: "",
                copyrights: [{ text: "", type: "" }],
                external_ids: {},
                genres: [],
                label: "",
                popularity: 0,
                album_group: "",
                artists: [
                  {
                    external_urls: { spotify: "" },
                    href: "",
                    id: "",
                    name: "",
                    type: "",
                    uri: "",
                  },
                ],
              },
              artists: [
                {
                  external_urls: { spotify: "" },
                  followers: { href: "", total: 0 },
                  genres: [],
                  href: "",
                  id: "",
                  images: [
                    {
                      url: "",
                      height: 0,
                      width: 0,
                    },
                  ],
                  name: "",
                  popularity: 0,
                  type: "",
                  uri: "",
                },
              ],
              available_markets: [],
              disc_number: 0,
              duration_ms: 0,
              explicit: false,
              external_ids: {},
              external_urls: { spotify: "" },
              href: "",
              id: "",
              is_playable: false,
              linked_from: {},
              restrictions: { reason: "" },
              name: "",
              popularity: 0,
              preview_url: "",
              track_number: 0,
              type: "",
              uri: "",
              is_local: false,
            },
          },
        ],
      },
      type: "",
      uri: "",
    },
    playlistFeatures: {
      audio_features: [
        {
          acousticness: 0.0,
          analysis_url: "",
          danceability: 0.0,
          duration_ms: 0,
          energy: 0.0,
          id: "",
          instrumentalness: 0.0,
          key: -1,
          liveness: 0.0,
          loudness: -0.0,
          mode: -1,
          speechiness: 0.0,
          tempo: 0.0,
          time_signature: 0,
          track_href: "",
          type: "",
          uri: "",
          valence: 0.0,
        },
      ],
    },
  },
  reducers: {
    updateAlbum: (state, action) => {
      state.albumMeta = action.payload;
    },
    updateArtist: (state, action) => {
      state.artistMeta = action.payload;
    },
    updateTrackFeatures: (state, action) => {
      state.trackFeatures = action.payload;
    },
    updateAlbumFeatures: (state, action) => {
      state.albumFeatures = action.payload;
    },
    updateTrackInfo: (state, action) => {
      state.trackMeta = action.payload;
    },
    updateArtistAlbums: (state, action) => {
      state.artistAlbums = action.payload;
    },
    updateSearchResults: (state, action) => {
      state.searchResults = action.payload;
    },
    updateFetchError: (state, action) => {
      state.fetchError = action.payload;
    },
    updatePlaylist: (state, action) => {
      state.playlistMeta = action.payload;
    },
    updatePlaylistFeatures: (state, action) => {
      state.playlistFeatures = action.payload;
    },
  },
});

export const {
  updateAlbum,
  updateArtist,
  updateTrackFeatures,
  updateTrackInfo,
  updateAlbumFeatures,
  updateArtistAlbums,
  updateSearchResults,
  updateFetchError,
  updatePlaylist,
  updatePlaylistFeatures,
} = resultSlice.actions;

export default resultSlice.reducer;
