import React, { useEffect, useState } from "react";
import { requestUserAccessToken } from "../functions/spotify-auth";
import { useNavigate } from "react-router";
import spotifyStore from "../store";
import { updateMode } from "../redux/searchSlice";

function checkLoggedIn() {
  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("code");
  return code != null;
}

const LoginRedirect = () => {
  const [gotCode, setGotCode] = useState(false);
  const [gotToken, setGotToken] = useState(false);
  const navigate = useNavigate();
  const [redirectWait, setRedirectWait] = useState(2);

  async function handleTokenRequest() {
    let token = await requestUserAccessToken();
    return token;
  }

  useEffect(() => {
    if (redirectWait == 2) {
      setGotCode(checkLoggedIn());
      console.log("Logged in", checkLoggedIn());
    }
    const interval = setInterval(() => {
      setRedirectWait((redirectWait) => redirectWait - 1);
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let token = handleTokenRequest();
    setGotToken(token);
  }, [gotCode]);

  useEffect(() => {
    if (gotToken && redirectWait < 0) {
      spotifyStore.dispatch(updateMode("user"));
      navigate("/spotify-tool");
    }
  }, [gotToken, redirectWait]);

  return (
    <>
      <h1>One sec, getting the stage set...</h1>
    </>
  );
};

export default LoginRedirect;
