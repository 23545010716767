import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAlbum,
  fetchArtist,
  fetchPlaylist,
  fetchTrackInfo,
} from "../functions/spotify-fetches";
import "../theme/search.css";
import TrackSearch from "../TrackSearch";
import {
  searchForAlbum,
  searchForArtist,
  searchForPlaylist,
  searchForTrack,
} from "../functions/spotify-search";
import { getYear } from "../functions/spotify-utility";

const DualSearch = () => {
  const deviceMode = useSelector((state) => state.utility.device);
  const fetchError = useSelector((state) => state.result.fetchError);

  const dispatch = useDispatch();
  const searchMode = useSelector((state) => state.search.mode);

  const [placeholderText, setPlaceholderText] = useState("");
  const [userInput, setUserInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [lastSearch, setLastSearch] = useState(" ");
  const [useURI, setUseURI] = useState(false);

  useEffect(() => {
    if (useURI) {
      setPlaceholderText(
        searchMode.charAt(0).toUpperCase() + searchMode.slice(1) + " ID or URL"
      );
    } else {
      setPlaceholderText("Search for " + searchMode);
    }
  }, [searchMode, useURI]);

  async function handleSearch() {
    if (userInput == "") {
      return;
    }
    if (useURI) {
      await handleFetch(userInput);
      setUserInput("");
      return;
    }

    switch (searchMode) {
      case "track":
        if (lastSearch != userInput) {
          setLastSearch(() => userInput);
          console.log('Searching: "' + userInput + '"');
          let results;
          results = await searchForTrack(userInput);
          if (typeof results.tracks != "undefined") {
            setSearchResults(results);
          }
        }
        break;
      case "artist":
        if (lastSearch != userInput) {
          setLastSearch(() => userInput);
          console.log('Searching: "' + userInput + '"');
          let results;
          results = await searchForArtist(userInput);
          if (typeof results.artists != "undefined") {
            setSearchResults(results);
          }
        }
        break;
      case "album":
        if (lastSearch != userInput) {
          setLastSearch(() => userInput);
          console.log('Searching: "' + userInput + '"');
          let results;
          results = await searchForAlbum(userInput);
          if (typeof results.albums != "undefined") {
            setSearchResults(results);
          }
        }
        break;
      case "playlist":
        if (lastSearch != userInput) {
          setLastSearch(() => userInput);
          console.log('Searching: "' + userInput + '"');
          let results;
          results = await searchForPlaylist(userInput);
          if (typeof results.playlists != "undefined") {
            setSearchResults(results);
          }
        }
        break;
      default:
        return;
    }
  }

  async function handleFetch(fetchID) {
    switch (searchMode) {
      case "track":
        await fetchTrackInfo(fetchID);
        break;
      case "artist":
        await fetchArtist(fetchID);
        break;
      case "album":
        await fetchAlbum(fetchID);
        break;
      case "playlist":
        await fetchPlaylist(fetchID);
        break;
      default:
        break;
    }
    setSearchResults([]);
  }

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  return (
    <>
      <div className={"section search-bar ".concat(deviceMode)}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
          }}
        >
          <h2>Search</h2>
          <button
            className={useURI ? "secondary" : "ternary"}
            onClick={() => setUseURI((useURI) => !useURI)}
          >
            Use
            {useURI ? " Keyword" : " URI/URL"}
          </button>
        </div>
        <input
          placeholder={placeholderText}
          onChange={(e) => handleInputChange(e)}
          value={userInput}
          onKeyUpCapture={(e) => {
            if (e.key == "Enter") {
              handleSearch();
            }
          }}
        ></input>

        <button className="primary" onClick={() => handleSearch()}>
          Search
        </button>

        <p className={fetchError.message == "" ? "no-message" : "message"}>
          {fetchError.mode == searchMode ? fetchError.message : "NO MESSAGE"}
        </p>
        {typeof searchResults.tracks == "undefined" ? (
          <></>
        ) : (
          <div className="inline-result-box">
            {searchResults.tracks.items.map((key) => {
              return (
                <div
                  className="inline-results track"
                  key={key.id}
                  onClick={() => handleFetch(key.id)}
                >
                  <img
                    src={key.album.images[key.album.images.length - 1].url}
                  ></img>
                  <p>{key.name}</p>
                  <p>{key.artists.map((x) => x.name).join(", ")}</p>
                </div>
              );
            })}
          </div>
        )}
        {typeof searchResults.artists == "undefined" ? (
          <></>
        ) : (
          <div className="inline-result-box">
            {searchResults.artists.items.map((key) => {
              return (
                <div
                  className="inline-results track"
                  key={key.id}
                  onClick={() => handleFetch(key.id)}
                >
                  {key.images[0] == null ||
                  typeof key.images[key.images.length - 1].url ==
                    "undefined" ? (
                    <></>
                  ) : (
                    <img src={key.images[key.images.length - 1].url}></img>
                  )}{" "}
                  <p>{key.name}</p>
                </div>
              );
            })}
          </div>
        )}
        {typeof searchResults.albums == "undefined" ? (
          <></>
        ) : (
          <div className="inline-result-box">
            {searchResults.albums.items.map((key) => {
              return (
                <div
                  className="inline-results track"
                  key={key.id}
                  onClick={() => handleFetch(key.id)}
                >
                  {key.images[0] == null ||
                  typeof key.images[key.images.length - 1].url ==
                    "undefined" ? (
                    <></>
                  ) : (
                    <img src={key.images[key.images.length - 1].url}></img>
                  )}{" "}
                  <p>{key.name}</p>
                  <p>
                    {key.artists.map((x) => x.name).join(", ")} (
                    {getYear(key.release_date)})
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {typeof searchResults.playlists == "undefined" ? (
          <></>
        ) : (
          <div className="inline-result-box">
            {searchResults.playlists.items.map((key) => {
              return (
                <div
                  className="inline-results track"
                  key={key.id}
                  onClick={() => handleFetch(key.id)}
                >
                  {key.images[0] == null ? (
                    <></>
                  ) : (
                    <img src={key.images[key.images.length - 1].url}></img>
                  )}
                  <p>{key.name}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default DualSearch;
