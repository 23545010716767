import { createSlice } from "@reduxjs/toolkit";

export const tokenSlice = createSlice({
  name: "token",
  initialState: {
    token: "",
    expiry: "22",
  },
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateExpiry: (state, action) => {
      state.expiry = action.payload;
    },
  },
});

export const { updateToken, updateExpiry } = tokenSlice.actions;

export default tokenSlice.reducer;
