import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserProfile } from "../functions/user-fetches";
import spotifyStore from "../store";
import { clearProfile } from "../redux/userSlice";
import { useNavigate } from "react-router";
import "../theme/userPage.css";
import CurrentlyPlaying from "./CurrentlyPlaying";
import UserShape from "./UserShape";
import { updateMode } from "../redux/searchSlice";
import UserArtistsGenres from "./UserArtistsGenres";
import iso31661Alpha2 from "iso-3166-1-alpha-2";

const UserHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.profile);
  const [userVisible, setUserVisibility] = useState(userProfile.id != "");

  function logOut() {
    localStorage.clear();
    spotifyStore.dispatch(clearProfile());
    navigate("/spotify-tool/logged-out");
  }

  function logIn() {
    dispatch(updateMode("login"));
    navigate("/spotify-tool/login");
  }

  async function getProfile() {
    await fetchUserProfile();
  }

  useEffect(() => {
    if (userProfile.id == "") {
      getProfile();
    }
  }, []);

  useEffect(() => {
    console.log(userProfile);
    setUserVisibility(userProfile.id != "");
  }, [userProfile]);

  const deviceMode = useSelector((state) => state.utility.device);

  return (
    <>
      {userVisible ? (
        <div className={"content ".concat(deviceMode)}>
          <div className={"section ".concat(deviceMode)}>
            <button className="primary log-out" onClick={() => logOut()}>
              Log Out
            </button>
            <h2>Hi there, {userProfile.display_name}!</h2>
            <a href={userProfile.external_urls.spotify}>
              <img
                className="profile-img"
                src={
                  userProfile.images[0] != null
                    ? userProfile.images[userProfile.images.length - 1].url
                    : ""
                }
              ></img>
            </a>
            <p>
              Spotify country: {iso31661Alpha2.getCountry(userProfile.country)}
            </p>
            <p>
              {parseInt(userProfile.followers.total).toLocaleString("en-US")}{" "}
              followers
            </p>
            <p>Spotify ID: {userProfile.id}</p>
          </div>
          <CurrentlyPlaying />
          <UserShape />
          <UserArtistsGenres />
        </div>
      ) : (
        <>
          <div className={"content ".concat(deviceMode)}>
            <div className={"section ".concat(deviceMode)}>
              <h2>Log in to access personal statistics</h2>
              <button className="primary log-out" onClick={() => logIn()}>
                Log In
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default UserHome;
