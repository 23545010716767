import React from "react";
import { useNavigate } from "react-router";

const LoggedOut = () => {
  const navigate = useNavigate();
  return (
    <>
      <h1>You've been logged out of the spotify tool.</h1>
      <button
        onClick={() => {
          navigate("/spotify-tool");
        }}
      >
        Back to General
      </button>
    </>
  );
};

export default LoggedOut;
