import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, createHashRouter, RouterProvider } from 'react-router-dom';

import ErrorPage from './ErrorPage';
import spotifyStore from './spotify-searcher/store';
import { Provider } from 'react-redux';
import SpotifyPage from './spotify-searcher/SpotifyPage';
import Login from "./spotify-searcher/userPages/Login";
import UserHome from './spotify-searcher/userPages/UserHome';
import LoginRedirect from './spotify-searcher/userPages/LoginRedirect';
import LoggedOut from './spotify-searcher/userPages/LoggedOut';
import { setDeviceDesktop, setDeviceMobile } from './spotify-searcher/redux/utility';
import { createHashHistory } from 'history';

const appRouter = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <Provider store={spotifyStore}><LoginRedirect /></Provider>
  },
  {
    path: "/spotify-tool/user/home",
    errorElement: <ErrorPage />,
    element: <Provider store={spotifyStore}><UserHome /></Provider>
  },
  {
    path: "/spotify-tool/logged-out",
    errorElement: <ErrorPage />,
    element: <Provider store={spotifyStore}><LoggedOut /></Provider>
  },
  {
    path: "/spotify-tool/login/redirect",
    errorElement: <ErrorPage />,
    element: <Provider store={spotifyStore}><LoginRedirect /></Provider>
  },
  {
    path: "/spotify-tool/login",
    errorElement: <ErrorPage />,
    element: <Provider store={spotifyStore}><Login /></Provider>
  }
  ,
  {
    path: "/spotify-tool/*",
    errorElement: <ErrorPage />,
    element: <Provider store={spotifyStore}><SpotifyPage /></Provider>
  },

])

console.log(window.innerHeight + ", " + window.innerWidth)
if (window.innerHeight > 1.5 * window.innerWidth) {
  spotifyStore.dispatch(setDeviceMobile());
} else {
  spotifyStore.dispatch(setDeviceDesktop());
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouter} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
