import React from "react";
import { refreshAccessCode, spotifyLogin } from "../functions/spotify-auth";

const Login = () => {
  spotifyLogin();

  return (
    <>
      <h1>One second, getting you logged in</h1>
    </>
  );
};

export default Login;
