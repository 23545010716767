import React, { useEffect, useState } from "react";
import {
  fetchTrackInfo,
  fetchTrackFeatures,
  fetchAlbum,
  fetchMultipleTrackFeatures,
  fetchPlaylist,
} from "./functions/spotify-fetches";
import "./theme/trackInformation.css";
import TrackRadarChart from "./components/TrackRadarChart";
import { AlbumBasicData, AlbumDetailsCard } from "./components/AlbumInfoCards";
import { useDispatch, useSelector } from "react-redux";
import { updateFetchError } from "./redux/resultSlice";
import QuickTrackList from "./components/QuickTrackList";
import SingleItemSearchBar from "./components/SingleItemSearchBar";
import IdTag from "./components/IdTag";
import "./theme/displayParts.css";
import { getMedianChart } from "./functions/spotify-utility";
import DualSearch from "./components/DualSearch";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  // speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

export const PlaylistSearch = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);

  const playlistState = useSelector((state) => state.result.playlistMeta);
  const playlistFeatures = useSelector(
    (state) => state.result.playlistFeatures
  );
  const fetchError = useSelector((state) => state.result.fetchError);
  const dispatch = useDispatch();
  const [urlInput, setUrlInput] = useState("");
  const [hideTracks, setHideTracks] = useState(true);
  const [genresList, setGenresList] = useState([]);

  const [collectiveRadarData, setCollectiveRadarData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        // speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000" },
    },
  ]);

  const [averageRadarData, setAverageRadarData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        // speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000" },
    },
  ]);

  const [playlistCover, setPlaylistCover] = useState("");

  const [trackRadarData, setTrackRadarData] = useState([
    [
      {
        data: {
          energy: 0.0,
          acousticness: 0.0,
          danceability: 0.0,
          liveness: 0.0,
          instrumentalness: 0.0,
          // speechiness: 0.0,
          valence: 0.0,
        },
        meta: { color: "#000" },
        index: 0,
      },
    ],
  ]);
  const [lastRequest, setLastRequest] = useState("");

  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (playlistState.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }

    if (typeof playlistState.images != "undefined") {
      setPlaylistCover(
        playlistState.images[playlistState.images.length - 1].url
      );
    }
  }, [playlistState]);

  const handleSearch = () => {
    if (lastRequest == urlInput || urlInput.length < 1) {
      return;
    }
    if (urlInput.length > 0) {
      setLastRequest(urlInput);
      fetchPlaylist(urlInput);
    }
  };

  function getTrackColor(featuresVals) {
    if (typeof featuresVals == "undefined") {
      return "#0000";
    }
    if (featuresVals == null) {
      return "#0000";
    }
    let val1 = featuresVals.energy - featuresVals.acousticness;
    let val2 = featuresVals.danceability - featuresVals.instrumentalness;
    let val3 = featuresVals.liveness - featuresVals.valence;
    let tempVals = [
      (1.0 - val1) * 16 * 16,
      (1.0 - 2 * Math.abs(0.5 - val2)) * 16 * 16,
      val3 * 16 * 16,
    ];

    let seg1 = Math.ceil(tempVals[0] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg1.length < 2) {
      seg1 = "0" + seg1;
    }

    let seg2 = Math.ceil(tempVals[1] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg2.length < 2) {
      seg2 = "0" + seg2;
    }
    let seg3 = Math.ceil(tempVals[2] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg3.length < 2) {
      seg3 = "0" + seg3;
    }
    return "#" + seg1 + seg2 + seg3;
  }

  function handleMulitpleFeaturesFetch() {
    var allIDs = [];
    var tracksList = playlistState.tracks.items;

    console.log(tracksList);
    for (let i = 0; i < tracksList.length; i++) {
      if (tracksList[i].track != null) {
        allIDs.push(tracksList[i].track.id);
      }
    }
    fetchMultipleTrackFeatures(allIDs);
  }

  const [aveColour, setAveColour] = useState("#fff0");

  function createRadarChart() {
    var features = playlistFeatures.audio_features;
    if (typeof features == "undefined") {
      return;
    }

    var perTrackRadarData = [];

    var multiRadarData = [];
    var averageFeatures = {
      acousticness: 0.0,
      analysis_url: "",
      danceability: 0.0,
      duration_ms: 0,
      energy: 0.0,
      id: "",
      instrumentalness: 0.0,
      key: -1,
      liveness: 0.0,
      loudness: -0.0,
      mode: -1,
      speechiness: 0.0,
      tempo: 0.0,
      time_signature: 0,
      track_href: "",
      type: "",
      uri: "",
      valence: 0.0,
    };
    var validCount = 0.0;

    for (let i = 0; i < features.length; i++) {
      if (features[i] != null) {
        validCount++;
        averageFeatures.acousticness += features[i].acousticness;
        averageFeatures.energy += features[i].energy;
        averageFeatures.danceability += features[i].danceability;
        averageFeatures.liveness += features[i].liveness;
        averageFeatures.instrumentalness += features[i].instrumentalness;
        averageFeatures.valence += features[i].valence;
        averageFeatures.speechiness += features[i].speechiness;

        multiRadarData.push({
          data: {
            energy: features[i].energy,
            acousticness: features[i].acousticness,
            danceability: features[i].danceability,
            liveness: features[i].liveness,
            instrumentalness: features[i].instrumentalness,
            speechiness: features[i].speechiness,
            valence: features[i].valence,
          },
          meta: {
            color: getTrackColor(features[i]),
          },
        });
        perTrackRadarData.push([
          {
            data: {
              energy: features[i].energy,
              acousticness: features[i].acousticness,
              danceability: features[i].danceability,
              liveness: features[i].liveness,
              instrumentalness: features[i].instrumentalness,
              speechiness: features[i].speechiness,
              valence: features[i].valence,
            },
            meta: {
              color: getTrackColor(features[i]),
            },
            index: i,
          },
        ]);
      } else {
        perTrackRadarData.push([
          {
            data: {
              energy: 0.0,
              acousticness: 0.0,
              danceability: 0.0,
              liveness: 0.0,
              instrumentalness: 0.0,
              speechiness: 0.0,
              valence: 0.0,
            },
            meta: {
              color: "#0000",
            },
            index: i,
          },
        ]);
      }
    }
    averageFeatures.acousticness /= validCount;
    averageFeatures.energy /= validCount;
    averageFeatures.danceability /= validCount;
    averageFeatures.liveness /= validCount;
    averageFeatures.instrumentalness /= validCount;
    averageFeatures.valence /= validCount;
    averageFeatures.speechiness /= validCount;
    console.log(averageFeatures);
    let averageColor = getTrackColor(averageFeatures);
    setAveColour(averageColor);
    setAverageRadarData(getMedianChart(multiRadarData));
    setCollectiveRadarData(multiRadarData);
    setTrackRadarData(perTrackRadarData);
  }

  useEffect(() => {
    createRadarChart();
    console.log(playlistState.owner);
  }, [playlistFeatures]);

  useEffect(() => {
    console.log(aveColour);
  }, [aveColour]);

  useEffect(() => {
    handleMulitpleFeaturesFetch();
  }, [playlistState]);

  useEffect(() => {
    dispatch(updateFetchError({ message: "", mode: "" }));
  }, []);

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  function handleSampleA() {
    setUrlInput(
      "https://open.spotify.com/album/5bfpRtBW7RNRdsm3tRyl3R?si=h68akCRhQEyfdoAmQ4kJpw"
    );
    handleSearch();
  }

  function handleSampleB() {
    setUrlInput(
      "https://open.spotify.com/album/6kZ42qRrzov54LcAk4onW9?si=zOg5ZjDPTlu-t_lgAyP3uQ"
    );
    handleSearch();
  }

  return (
    <div className={"content ".concat(deviceMode)}>
      <div className="grid-chunk">
        {/* <SingleItemSearchBar /> */}
        <DualSearch />
        <div
          className={"section meta-card ".concat(deviceMode)}
          style={playlistState.id == "" ? { display: "none" } : {}}
        >
          <div className="meta-image">
            <a href={playlistState.external_urls.spotify} target="_blank">
              <img
                src={playlistState.images[playlistState.images.length - 1].url}
              ></img>
            </a>
          </div>
          <p>{playlistState.name}</p>
          <p
            style={{
              maxWidth: "350px",
              alignSelf: "center",
              textAlign: "left",
            }}
          >
            {playlistState.description}
          </p>
          <p>
            {playlistState.followers.total.toLocaleString("en-US")} followers
          </p>
          <p>
            Owned by{" "}
            {playlistState.owner.display_name == null
              ? playlistState.owner.id
              : playlistState.owner.display_name}
          </p>
        </div>
      </div>
      <div
        className={"section ".concat(deviceMode)}
        style={playlistState.id == "" ? { display: "none" } : {}}
      >
        <TrackRadarChart
          radardata={collectiveRadarData}
          labels={radarLabels}
          hide={false}
          title={"All Playlist Characteristics"}
        />
      </div>

      <div
        className={"section ".concat(deviceMode)}
        style={playlistState.id == "" ? { display: "none" } : {}}
      >
        <TrackRadarChart
          radardata={averageRadarData}
          labels={radarLabels}
          hide={false}
          title={"Average Playlist Characteristics"}
          mode={"average"}
        />
      </div>

      <div
        className={"section ".concat(deviceMode)}
        style={
          playlistState.id == ""
            ? { display: "none" }
            : { flexDirection: "column", padding: "1em", width: "100%" }
        }
      >
        <button
          style={{
            height: "min-content",
            width: "fit-content",
            flexGrow: 0,
            flexShrink: 0,
          }}
          onClick={() => setHideTracks(!hideTracks)}
        >
          {hideTracks ? "Show tracks" : "Hide tracks"}
        </button>
        <h2>Playlist Tracks</h2>
        {hideTracks ? (
          <></>
        ) : (
          <ol>
            {playlistState.tracks.items.map((item) => {
              return (
                <li key={item.track.id}>
                  <div className="name-id">
                    <p style={{ fontWeight: 800 }}>{item.track.name} </p>
                    <IdTag tag={item.track.id} type={"track"} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      fontWeight: 500,
                      padding: "0.2em 0.24em .2em 1em",
                    }}
                  >
                    Artists:
                    {item.track.artists.map((artist) => {
                      return (
                        <div
                          className="name-id"
                          style={{
                            padding: "0.2em 0.24em .2em 1em",
                            fontWeight: 400,
                          }}
                        >
                          <p>{artist.name} </p>
                          <IdTag tag={artist.id} type={"artist"} />
                        </div>
                      );
                    })}
                    Album:
                    <div
                      className="name-id"
                      style={{
                        padding: "0.2em 0.24em .2em 1em",
                        fontWeight: 400,
                      }}
                    >
                      <p>{item.track.album.name} </p>
                      <IdTag tag={item.track.album.id} type={"album"} />
                    </div>
                    <p style={{ fontWeight: 400 }}>Added {item.added_at}</p>
                  </div>
                </li>
              );
            })}
          </ol>
        )}
      </div>
    </div>
  );
};
