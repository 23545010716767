import React, { useEffect, useState } from "react";
import { fetchCurrentlyPlaying } from "../functions/user-fetches";
import { useDispatch, useSelector } from "react-redux";
import "../theme/userPage.css";
import { updateCPProgress, updateCurrentlyPlaying } from "../redux/userSlice";
import { getDurationStr } from "../functions/spotify-utility";

const CurrentlyPlaying = () => {
  const deviceMode = useSelector((state) => state.utility.device);

  const curPlaying = useSelector((state) => state.user.currentlyPlaying);
  const [loadedFirst, setLoadedFirst] = useState(false);
  const [fetchTimer, setFetchTimer] = useState(0);

  function getImageIndex() {
    if (curPlaying.item.album.images.length > 1) {
      return 1;
    } else {
      return 0;
    }
  }

  function getYear(releaseDate) {
    const yearRegex = /[0-9]{4}/;
    const yearMatch = yearRegex.exec(releaseDate);
    if (yearMatch != null) {
      return yearMatch[0];
    } else {
      return "";
    }
  }

  useEffect(() => {
    console.log(fetchTimer);
    if (!loadedFirst) {
      dispatch(updateCPProgress(-1000));
      setLoadedFirst(true);
      setFetchTimer(() => 30);
      fetchCurrentlyPlaying();
    } else {
      if (
        fetchTimer <= 0 ||
        curPlaying.progress_ms >= curPlaying.item.duration_ms - 1000
      ) {
        setFetchTimer(() => 30);
        fetchCurrentlyPlaying();
      }
    }
  }, [fetchTimer]);

  const dispatch = useDispatch();

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(updateCPProgress(1000));
      setFetchTimer((fetchTimer) => fetchTimer - 1);
    }, 1000);
    return () => clearInterval(interval);
    // Updates the song that's currently playing every 15 seconds
  }, []);

  return (
    <div className={"section ".concat(deviceMode)}>
      <h2>
        {curPlaying.item.id != "" ? "Currently Playing" : "Nothing Playing"}
      </h2>
      {curPlaying.item.id != "" ? (
        <div className="currently-playing">
          <img src={curPlaying.item.album.images[getImageIndex()].url}></img>
          <div className="details">
            <p>{curPlaying.item.name}</p>
            <p className="id-tag">{curPlaying.item.id}</p>
            <p>by {curPlaying.item.artists.map((x) => x.name).join(", ")}</p>
            <p>
              {curPlaying.item.album.name} (
              {getYear(curPlaying.item.album.release_date)})
            </p>

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "min-content auto min-content",
                alignContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <p
                style={{
                  fontSize: "10pt",
                  fontWeight: 400,
                  color: "var(--text-secondary)",
                  width: "2.5em",
                }}
              >
                {getDurationStr(curPlaying.progress_ms)}
              </p>
              <div
                style={{
                  height: "1.5ch",
                  background: "var(--background-body)",
                  borderRadius: "var(--section-radius)",
                  boxShadow: "inset var(--content-shadow)",
                  margin: 0,
                  padding: 0,
                }}
              >
                <div
                  style={{
                    height: "100%",
                    left: 0,
                    width:
                      (curPlaying.progress_ms * 100) /
                        curPlaying.item.duration_ms +
                      "%",
                    background: "var(--primary)",
                    borderRadius: "var(--section-radius)",
                  }}
                ></div>
              </div>
              <p
                style={{
                  fontSize: "10pt",
                  fontWeight: 400,
                  color: "var(--text-secondary)",
                  fontStyle: "normal",
                  opacity: "100%",
                  width: "2.5em",
                }}
              >
                {getDurationStr(
                  curPlaying.item.duration_ms - curPlaying.progress_ms
                )}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CurrentlyPlaying;
