import React, { useEffect, useState } from "react";
import VerticalBar from "../VerticalBar";
import {
  fetchArtist,
  fetchArtistAlbums,
  fetchTrackInfo,
} from "../functions/spotify-fetches";
import "../theme/artistInfo.css";
import { useSelector } from "react-redux";
import IdTag from "./IdTag";

export const ArtistInfo = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);
  const artistState = useSelector((state) => state.result.artistMeta);
  const artistAlbums = useSelector((state) => state.result.artistAlbums);
  const [lastRequest, setLastRequest] = useState("");

  function getImageIndex() {
    if (artistState.images.length > 1) {
      return 1;
    } else {
      return 0;
    }
  }

  function getGenres() {
    var genres = [];
    for (let i = 0; i < artistState.genres.length; i++) {
      genres.push(artistState.genres[i]);
    }
    return genres.join(", ");
  }

  function getYear(albumYear) {
    const yearRegex = /[0-9]{4}/;
    const yearMatch = yearRegex.exec(albumYear);
    if (yearMatch != null) {
      return yearMatch[0];
    } else {
      return "";
    }
  }

  return (
    <>
      <div
        className={"section meta-card ".concat(deviceMode)}
        style={artistState.id == "" ? { display: "none" } : {}}
      >
        <div className="meta-image">
          <a href={artistState.external_urls.spotify} target="_blank">
            {artistState.images[0] == null ? (
              <></>
            ) : (
              <img
                src={artistState.images[getImageIndex()].url}
                className="artist-image"
              ></img>
            )}
          </a>
        </div>
        <p>{artistState.name}</p>

        <IdTag tag={artistState.id} lookup={false} />
        <p>Followers: {artistState.followers.total.toLocaleString("us-EN")}</p>
        <p>
          Genres:{" "}
          {artistState.genres.map((genre) => {
            return (
              <em
                style={{
                  borderRadius: "4px",
                  background: "var(--background-button-secondary)",
                  padding: "0.2em 0.4em",
                  margin: "0.2em",
                  fontStyle: "normal",
                }}
              >
                {" "}
                {genre}{" "}
              </em>
            );
          })}
        </p>
        <p>Popularity: {artistState.popularity}/100</p>
      </div>
      <div
        className={"section ".concat(deviceMode)}
        style={artistState.id == "" ? { display: "none" } : {}}
      >
        <h2>Albums</h2>
        <div className="album-card-partition">
          {artistAlbums.items.map((key) => {
            if (key.album_type == "album") {
              return (
                <div key={key.id} className="album-card">
                  <img
                    src={key.images[key.images.length - 1].url}
                    style={{ gridArea: "a", alignSelf: "center" }}
                  />
                  <p
                    style={{
                      gridArea: "b",
                    }}
                  >
                    {key.name}
                  </p>

                  <p>({getYear(key.release_date)})</p>
                  <div style={{ gridArea: "d" }}>
                    <IdTag tag={key.id} type={"album"} />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <h2>Singles</h2>
        <div className="album-card-partition">
          {artistAlbums.items.map((key) => {
            if (key.album_type == "single") {
              return (
                <div key={key.id} className="album-card">
                  <img
                    src={key.images[key.images.length - 1].url}
                    style={{ gridArea: "a", alignSelf: "center" }}
                  />
                  <p
                    style={{
                      gridArea: "b",
                    }}
                  >
                    {key.name}
                  </p>
                  <p>({getYear(key.release_date)})</p>
                  <div style={{ gridArea: "d" }}>
                    <IdTag tag={key.id} type={"album"} />
                  </div>
                </div>
              );
            }
          })}
        </div>
        <h2>Compilations</h2>
        <div className="album-card-partition">
          {artistAlbums.items.map((key) => {
            if (key.album_type == "compilation") {
              return (
                <div key={key.id} className="album-card">
                  <img
                    src={key.images[key.images.length - 1].url}
                    style={{ gridArea: "a", alignSelf: "center" }}
                  />
                  <p
                    style={{
                      gridArea: "b",
                    }}
                  >
                    {key.name}
                  </p>
                  <p>({getYear(key.release_date)})</p>
                  <div style={{ gridArea: "d" }}>
                    <IdTag tag={key.id} type={"album"} />
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </>
  );
};
