import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../theme/trackInformation.css";
import IdTag from "./IdTag";

export const AlbumBasicData = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);

  const albumState = useSelector((state) => state.result.albumMeta);

  const [hide, setHide] = useState(true);

  useEffect(() => {
    if (albumState.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [albumState]);

  function getImageIndex() {
    if (albumState.images.length > 1) {
      return 1;
    } else {
      return 0;
    }
  }

  function getArtistNames() {
    var artistNames = [];
    for (let i = 0; i < albumState.artists.length; i++) {
      artistNames.push(albumState.artists[i].name);
    }
    return artistNames.join(", ");
  }

  function getYear() {
    const yearRegex = /[0-9]{4}/;
    const yearMatch = yearRegex.exec(albumState.release_date);
    if (yearMatch != null) {
      return yearMatch[0];
    } else {
      return "";
    }
  }

  return (
    <div className={"section meta-card ".concat(deviceMode)}>
      <div className="meta-image">
        <a href={albumState.external_urls.spotify} target="_blank">
          <img
            src={albumState.images[getImageIndex()].url}
            className="album-image"
          ></img>
        </a>
      </div>
      <p>"{albumState.name}"</p>
      <p>{getArtistNames()}</p>
      <p>{getYear()}</p>
    </div>
  );
};

export const AlbumDetailsCard = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);

  const albumState = useSelector((state) => state.result.albumMeta);

  const [hide, setHide] = useState(true);
  const [hideRelated, setHideRelated] = useState(true);

  useEffect(() => {
    if (albumState.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [albumState]);

  return (
    <>
      {" "}
      <div
        className={"section ".concat(deviceMode)}
        style={
          hide
            ? { display: "none" }
            : {
                alignSelf: "start",
                flexDirection: "column",
                justifySelf: "stretch",
                alignSelf: "stretch",
                flexGrow: 1,
              }
        }
      >
        <div className="track-data detail-data">
          <h2>Album Details</h2>
          <p>Popularity: {albumState.popularity}/100</p>
          <p>Tracks: {albumState.total_tracks}</p>
          <p>Explicit: {albumState.explicit ? "Yes" : "No"}</p>
          <p>Label: {albumState.label}</p>
          <p>Copyrights:</p>
          <ul>
            {albumState.copyrights.map((key) => {
              return <li key={key.type}> {key.text}</li>;
            })}
          </ul>
          <p>External IDs:</p>
          <ul>
            {typeof albumState.external_ids.isrc == "undefined" ? (
              <></>
            ) : (
              <li>{"IRSC: " + albumState.external_ids.irsc}</li>
            )}
            {typeof albumState.external_ids.ean == "undefined" ? (
              <></>
            ) : (
              <li>{"EAN: " + albumState.external_ids.ean}</li>
            )}
            {typeof albumState.external_ids.upc == "undefined" ? (
              <></>
            ) : (
              <li>{"UPC: " + albumState.external_ids.upc}</li>
            )}
          </ul>
        </div>
      </div>
      <div
        className={"section ".concat(deviceMode)}
        style={{ flexGrow: 0, height: "fit-content" }}
      >
        <div className="track-data detail-data">
          <button onClick={() => setHideRelated(!hideRelated)}>
            {hideRelated ? "Show related IDs" : "Hide"}
          </button>
          <h2>Related ID Numbers</h2>
          {hideRelated ? (
            <></>
          ) : (
            <>
              <p>Album ID:</p>
              <IdTag tag={albumState.id} type={"album"} lookup={false} />
              <p>Artist IDs:</p>
              <ul style={{ listStyle: "none" }}>
                {albumState.artists.map((key) => {
                  return (
                    <li key={key.id} style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "nowrap",
                        }}
                      >
                        <p>{key.name}: </p>
                        <IdTag tag={key.id} type={"artist"} />
                      </div>
                    </li>
                  );
                })}
              </ul>
              <p>Track IDs: </p>
              <ol>
                {albumState.tracks.items.map((key) => {
                  return (
                    <li key={key.id} style={{}}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexWrap: "nowrap",
                        }}
                      >
                        <p>{key.name}</p>
                        <IdTag
                          tag={key.id}
                          type={"track"}
                          justify={"center"}
                          align={"center"}
                        />
                      </div>
                    </li>
                  );
                })}
              </ol>
            </>
          )}
        </div>
      </div>
    </>
  );
};
