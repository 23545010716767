import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    profile: {
      country: "",
      display_name: "",
      email: "",
      explicit_content: { filter_enabled: false, filter_locked: false },
      external_urls: { spotify: "" },
      followers: { href: "", total: 0 },
      href: "",
      id: "",
      images: [
        {
          url: "",
          height: 0,
          width: 0,
        },
      ],
      product: "",
      type: "",
      uri: "",
    },
    currentlyPlaying: {
      device: {
        id: "",
        is_active: false,
        is_private_session: false,
        is_restricted: false,
        name: "",
        type: "",
        volume_percent: 59,
      },
      repeat_state: "",
      shuffle_state: false,
      context: {
        type: "",
        href: "",
        external_urls: { spotify: "" },
        uri: "",
      },
      timestamp: 0,
      progress_ms: 0,
      is_playing: false,
      item: {
        album: {
          album_type: "",
          total_tracks: 9,
          available_markets: [],
          external_urls: { spotify: "" },
          href: "",
          id: "",
          images: [
            {
              url: "",
              height: 0,
              width: 0,
            },
          ],
          name: "",
          release_date: "",
          release_date_precision: "",
          restrictions: { reason: "" },
          type: "",
          uri: "",
          copyrights: [{ text: "", type: "" }],
          external_ids: { isrc: "", ean: "", upc: "" },
          genres: [],
          label: "",
          popularity: 0,
          album_group: "",
          artists: [
            {
              external_urls: { spotify: "" },
              href: "",
              id: "",
              name: "",
              type: "",
              uri: "",
            },
          ],
        },
        artists: [
          {
            external_urls: { spotify: "" },
            followers: { href: "", total: 0 },
            genres: [],
            href: "",
            id: "",
            images: [
              {
                url: "",
                height: 300,
                width: 300,
              },
            ],
            name: "",
            popularity: 0,
            type: "",
            uri: "",
          },
        ],
        available_markets: [],
        disc_number: 0,
        duration_ms: 0,
        explicit: false,
        external_ids: { isrc: "", ean: "", upc: "" },
        external_urls: { spotify: "" },
        href: "",
        id: "",
        is_playable: false,
        linked_from: {},
        restrictions: { reason: "" },
        name: "",
        popularity: 0,
        preview_url: "",
        track_number: 0,
        type: "",
        uri: "",
        is_local: false,
      },
      currently_playing_type: "",
      actions: {
        interrupting_playback: false,
        pausing: false,
        resuming: false,
        seeking: false,
        skipping_next: false,
        skipping_prev: false,
        toggling_repeat_context: false,
        toggling_shuffle: false,
        toggling_repeat_track: false,
        transferring_playback: false,
      },
    },
    topTracks: {
      href: "",
      limit: 0,
      next: "",
      offset: 0,
      previous: "",
      total: 0,
      items: [
        {
          album: {
            album_type: "",
            artists: [
              {
                external_urls: {
                  spotify: "",
                },
                href: "",
                id: "",
                name: "",
                type: "",
                uri: "",
              },
            ],
            available_markets: [],
            external_urls: {
              spotify: "",
            },
            href: "",
            id: "",
            images: [
              {
                height: 0,
                url: "0",
                width: 0,
              },
            ],
            name: "",
            release_date: "",
            release_date_precision: "",
            total_tracks: 0,
            type: "",
            uri: "",
            label: "",
          },
          album_group: "",
          artists: [
            {
              followers: { href: "", total: 0 },
              external_urls: {
                spotify: "",
              },
              images: [{ url: "", height: 0, width: 0 }],
              popularity: 0,
              href: "",
              id: "",
              name: "",
              type: "",
              uri: "",
            },
          ],
          available_markets: [],
          disc_number: 0,
          duration_ms: 0,
          explicit: false,
          external_ids: {
            isrc: "",
            ean: "",
            upc: "",
          },
          copyights: [{ text: "", type: "" }],
          external_urls: {
            spotify: "",
          },
          href: "",
          id: "",
          is_local: false,
          name: "",
          popularity: 0,
          preview_url: null,
          track_number: 0,
          type: "",
          uri: "",
        },
      ],
    },
    topArtists: {
      href: "",
      limit: 0,
      next: "",
      offset: 0,
      previous: "",
      total: 0,
      items: [
        {
          external_urls: {
            spotify: "",
          },
          followers: {
            href: "",
            total: 0,
          },
          genres: [],
          href: "",
          id: "",
          images: [
            {
              url: "",
              height: 0,
              width: 0,
            },
          ],
          name: "",
          popularity: 0,
          type: "",
          uri: "",
        },
      ],
    },
    following: {
      artists: {
        href: "",
        limit: 0,
        next: "",
        cursors: {
          after: "",
          before: "",
        },
        total: 0,
        items: [
          {
            external_urls: {
              spotify: "",
            },
            followers: {
              href: "",
              total: 0,
            },
            genres: [],
            href: "",
            id: "",
            images: [
              {
                url: "",
                height: 0,
                width: 0,
              },
            ],
            name: "",
            popularity: 0,
            type: "",
            uri: "",
          },
        ],
      },
    },
  },
  reducers: {
    updateProfile: (state, action) => {
      state.profile = action.payload;
    },
    clearProfile: (state) => {
      state.profile = {
        country: "",
        display_name: "",
        email: "",
        explicit_content: { filter_enabled: false, filter_locked: false },
        external_urls: { spotify: "" },
        followers: { href: "", total: 0 },
        href: "",
        id: "",
        images: [
          {
            url: "",
            height: 0,
            width: 0,
          },
        ],
        product: "",
        type: "",
        uri: "",
      };
    },
    updateCurrentlyPlaying: (state, action) => {
      state.currentlyPlaying = action.payload;
    },
    clearCurrentlyPlaying: (state, action) => {
      state.currentlyPlaying = {
        device: {
          id: "",
          is_active: false,
          is_private_session: false,
          is_restricted: false,
          name: "",
          type: "",
          volume_percent: 59,
        },
        repeat_state: "",
        shuffle_state: false,
        context: {
          type: "",
          href: "",
          external_urls: { spotify: "" },
          uri: "",
        },
        timestamp: 0,
        progress_ms: 0,
        is_playing: false,
        item: {
          album: {
            album_type: "",
            total_tracks: 9,
            available_markets: [],
            external_urls: { spotify: "" },
            href: "",
            id: "",
            images: [
              {
                url: "",
                height: 0,
                width: 0,
              },
            ],
            name: "",
            release_date: "",
            release_date_precision: "",
            restrictions: { reason: "" },
            type: "",
            uri: "",
            copyrights: [{ text: "", type: "" }],
            external_ids: { isrc: "", ean: "", upc: "" },
            genres: [],
            label: "",
            popularity: 0,
            album_group: "",
            artists: [
              {
                external_urls: { spotify: "" },
                href: "",
                id: "",
                name: "",
                type: "",
                uri: "",
              },
            ],
          },
          artists: [
            {
              external_urls: { spotify: "" },
              followers: { href: "", total: 0 },
              genres: [],
              href: "",
              id: "",
              images: [
                {
                  url: "",
                  height: 300,
                  width: 300,
                },
              ],
              name: "",
              popularity: 0,
              type: "",
              uri: "",
            },
          ],
          available_markets: [],
          disc_number: 0,
          duration_ms: 0,
          explicit: false,
          external_ids: { isrc: "", ean: "", upc: "" },
          external_urls: { spotify: "" },
          href: "",
          id: "",
          is_playable: false,
          linked_from: {},
          restrictions: { reason: "" },
          name: "",
          popularity: 0,
          preview_url: "",
          track_number: 0,
          type: "",
          uri: "",
          is_local: false,
        },
        currently_playing_type: "",
        actions: {
          interrupting_playback: false,
          pausing: false,
          resuming: false,
          seeking: false,
          skipping_next: false,
          skipping_prev: false,
          toggling_repeat_context: false,
          toggling_shuffle: false,
          toggling_repeat_track: false,
          transferring_playback: false,
        },
      };
    },
    updateTopTracks: (state, action) => {
      state.topTracks = action.payload;
    },
    clearTopTracks: (state) => {
      state.topTracks = {
        href: "",
        limit: 0,
        next: "",
        offset: 0,
        previous: "",
        total: 0,
        items: [
          {
            album: {
              album_type: "",
              artists: [
                {
                  external_urls: {
                    spotify: "",
                  },
                  href: "",
                  id: "",
                  name: "",
                  type: "",
                  uri: "",
                },
              ],
              available_markets: [],
              external_urls: {
                spotify: "",
              },
              href: "",
              id: "",
              images: [
                {
                  height: 0,
                  url: "0",
                  width: 0,
                },
              ],
              name: "",
              release_date: "",
              release_date_precision: "",
              total_tracks: 0,
              type: "",
              uri: "",
              label: "",
            },
            album_group: "",
            artists: [
              {
                followers: { href: "", total: 0 },
                external_urls: {
                  spotify: "",
                },
                images: [{ url: "", height: 0, width: 0 }],
                popularity: 0,
                href: "",
                id: "",
                name: "",
                type: "",
                uri: "",
              },
            ],
            available_markets: [],
            disc_number: 0,
            duration_ms: 0,
            explicit: false,
            external_ids: {
              isrc: "",
              ean: "",
              upc: "",
            },
            copyights: [{ text: "", type: "" }],
            external_urls: {
              spotify: "",
            },
            href: "",
            id: "",
            is_local: false,
            name: "",
            popularity: 0,
            preview_url: null,
            track_number: 0,
            type: "",
            uri: "",
          },
        ],
      };
    },
    updateTopArtists: (state, action) => {
      state.topArtists = action.payload;
    },
    clearTopArtists: (state) => {
      state.topArtists = {
        href: "",
        limit: 0,
        next: "",
        offset: 0,
        previous: "",
        total: 0,
        items: [
          {
            external_urls: {
              spotify: "",
            },
            followers: {
              href: "",
              total: 0,
            },
            genres: [],
            href: "",
            id: "",
            images: [
              {
                url: "",
                height: 0,
                width: 0,
              },
            ],
            name: "",
            popularity: 0,
            type: "",
            uri: "",
          },
        ],
      };
    },
    updateFollowing: (state, action) => {
      state.following = action.payload;
    },
    clearFollowing: (state) => {
      state.following = {
        artists: {
          href: "",
          limit: 0,
          next: "",
          cursors: {
            after: "",
            before: "",
          },
          total: 0,
          items: [
            {
              external_urls: {
                spotify: "",
              },
              followers: {
                href: "",
                total: 0,
              },
              genres: [],
              href: "",
              id: "",
              images: [
                {
                  url: "",
                  height: 0,
                  width: 0,
                },
              ],
              name: "",
              popularity: 0,
              type: "",
              uri: "",
            },
          ],
        },
      };
    },
    updateCPProgress: (state, action) => {
      state.currentlyPlaying.progress_ms =
        state.currentlyPlaying.progress_ms + action.payload;
    },
  },
});

export const {
  updateProfile,
  clearProfile,
  updateCurrentlyPlaying,
  clearCurrentlyPlaying,
  updateTopTracks,
  clearTopTracks,
  updateTopArtists,
  clearTopArtists,
  updateFollowing,
  clearFollowing,
  updateCPProgress,
} = userSlice.actions;

export default userSlice.reducer;
