import { requestToken } from "./spotify-auth";
import spotifyStore from "../store";
import { updateAlbum, updateAlbumFeatures, updateArtist, updateArtistAlbums, updateFetchError, updatePlaylist, updatePlaylistFeatures, updateSearchResults, updateTrackFeatures, updateTrackInfo } from "../redux/resultSlice";
import { updateAvailableGenres, updateRecommendationResults } from "../redux/recommendationSlice";


export async function fetchArtist(artistInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (artistInput == null) {
    spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid artist URL or ID number.", mode: "artist" }));
    return;
  }
  let artistID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(artistInput) == null) {
    // it's just a trackID
    const trackIDRegex = /^([a-zA-Z0-9]+)$/;
    const artistMatch = trackIDRegex.exec(artistInput);
    if (artistMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid artist ID number.", mode: "artist" }));
      return;
    } else {
      artistID = artistMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const artistMatch = urlRegex.exec(artistInput);
    if (artistMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid artist URL.", mode: "artist" }));
      return;
    } else {
      artistID = artistMatch[1];
    }
  }

  let artistData;
  let fetchResponse;
  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/artists/" + artistID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    if (fetchResponse.ok) {
      artistData = await fetchResponse.json();
      spotifyStore.dispatch(updateArtist(artistData));
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));


    } else {
      spotifyStore.dispatch(updateFetchError({ message: "No artist matching that URL or ID number could be found.", mode: "artist" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Sorry, an artist matching that URL or ID number couldn't be found.", active: true }));
    console.log(error);
  }
}

export async function fetchArtistAlbums(artistInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (artistInput == null) {
    console.log("artistURL is empty.");
    return;
  }
  let artistID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(artistInput) == null) {
    // it's just a trackID
    const trackIDRegex = /^([a-zA-Z0-9]+)$/;
    const artistMatch = trackIDRegex.exec(artistInput);
    if (artistMatch == null) {
      return;
    } else {
      artistID = artistMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const artistMatch = urlRegex.exec(artistInput);
    if (artistMatch == null) {
      return;
    } else {
      artistID = artistMatch[1];
    }
  }

  let artistData = {};

  await fetch("https://api.spotify.com/v1/artists/" + artistID + "/albums?limit=50", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .then((rjson) => {
      artistData = rjson;
    });
  spotifyStore.dispatch(updateArtistAlbums(artistData));
  return artistData;
}

export async function fetchAlbum(albumInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (albumInput == null) {
    spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid artist URL or ID number.", mode: "album" }));
    return;
  }
  let albumID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(albumInput) == null) {
    const trackIDRegex = /^([a-zA-Z0-9]{5,})$/;
    const albumMatch = trackIDRegex.exec(albumInput);
    if (albumMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid album ID number.", mode: "album" }));
      return;
    } else {
      albumID = albumMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const albumMatch = urlRegex.exec(albumInput);
    if (albumMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid album URL.", mode: "album" }));
      return;
    } else {
      albumID = albumMatch[1];
    }
  }

  let albumData;
  let fetchResponse;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/albums/" + albumID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (fetchResponse.ok) {
      albumData = await fetchResponse.json();
      spotifyStore.dispatch(updateAlbum(albumData));
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));


    } else {
      spotifyStore.dispatch(updateFetchError({ message: "No album matching that URL or ID number could be found.", mode: "album" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Sorry, an album matching that URL or ID number couldn't be found.", mode: "album" }));
    console.log(error);
  }
}


export async function fetchTrackFeatures(trackInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (trackInput == null) {
    console.log("trackURL is empty.");
    return;
  }
  let trackID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(trackInput) == null) {
    // it's just a trackID
    const trackIDRegex = /^([a-zA-Z0-9]+)$/;
    const trackMatch = trackIDRegex.exec(trackInput);
    if (trackMatch == null) {
      return;
    } else {
      trackID = trackMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const trackMatch = urlRegex.exec(trackInput);
    if (trackMatch == null) {
      return;
    } else {
      trackID = trackMatch[1];
    }
  }

  let trackData = {};

  await fetch("https://api.spotify.com/v1/audio-features/" + trackID, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .then((rjson) => {
      trackData = rjson;
    });
  spotifyStore.dispatch(updateTrackFeatures(trackData));
  return trackData;
}

export async function fetchMultipleTrackFeatures(trackIDs) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  let trackData = { audio_features: [] };
  let searchIDs = "";
  if (trackIDs.length > 1) {
    searchIDs = encodeURIComponent(trackIDs.join(","));
  } else {
    searchIDs = encodeURIComponent(trackIDs[0]);
  }

  let fetchResult;

  try {
    fetchResult = await fetch("https://api.spotify.com/v1/audio-features?ids=" + searchIDs, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
    if (fetchResult.ok) {
      trackData = await fetchResult.json();

      if (spotifyStore.getState((state) => state.search.mode).search.mode == "album") {
        spotifyStore.dispatch(updateAlbumFeatures(trackData));
      } else if (spotifyStore.getState((state) => state.search.mode).search.mode == "playlist") {
        spotifyStore.dispatch(updatePlaylistFeatures(trackData));
      }
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "Could not retrieve track features for provided list.", mode: "track" }));
      throw new Error("HTTP error! status: " + fetchResult.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Could not retrieve track features for provided list.", mode: "track" }));
    console.log(error);
  }
  return trackData;
}



export async function fetchTrackInfo(trackInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (trackInput == null || trackInput == "") {
    return;
  }
  let trackID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(trackInput) == null) {
    // it's just a trackID
    const trackIDRegex = /^([a-zA-Z0-9]+)$/;
    const trackMatch = trackIDRegex.exec(trackInput);
    if (trackMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid track ID number.", mode: "track" }));
      return;
    } else {
      trackID = trackMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const trackMatch = urlRegex.exec(trackInput);
    if (trackMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid track URL.", mode: "track" }));
      return;
    } else {
      trackID = trackMatch[1];
    }
  }
  let trackData;
  let fetchResponse;
  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/tracks/" + trackID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (fetchResponse.ok) {
      trackData = await fetchResponse.json();
      spotifyStore.dispatch(updateTrackInfo(trackData));
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));


    } else {
      spotifyStore.dispatch(updateFetchError({ message: "No track matching that URL or ID number could be found.", mode: "track" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Sorry, a track matching that URL or ID number couldn't be found.", mode: "track" }));
    console.log(error);
  }
}


export async function fetchTrackAnalysis(trackInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (trackInput == null) {
    console.log("trackURL is empty.");
    return;
  }
  let trackID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(trackInput) == null) {
    // it's just a trackID
    const trackIDRegex = /^([a-zA-Z0-9]+)$/;
    const trackMatch = trackIDRegex.exec(trackInput);
    if (trackMatch == null) {
      return;
    } else {
      trackID = trackMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const trackMatch = urlRegex.exec(trackInput);
    if (trackMatch == null) {
      return;
    } else {
      trackID = trackMatch[1];
    }
  }


  let trackData = {};

  await fetch("https://api.spotify.com/v1/audio-analysis/" + trackID, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  })
    .then((res) => res.json())
    .then((rjson) => {
      trackData = rjson;
    });
  return trackData;
}

export async function fetchGeneralSearch(searchQuery, types) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (searchQuery == null) {
    console.log("search term is empty.");
    return;
  } else if (searchQuery == "") {
    console.log("search term is empty.");
    return;
  }
  let searchTerm = encodeURIComponent(searchQuery);


  if (typeof types != typeof [""]) {
    console.log("Invalid search type");
    return;
  }
  let searchTypes;
  if (types.length == 0) {
    searchTypes = "album,track,artist,playlist";
  } else {
    searchTypes = types.join(",");
  }


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/search?q=" + searchTerm + "&type=" + encodeURIComponent(searchTypes), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      spotifyStore.dispatch(updateSearchResults(searchResult)); spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

  return searchResult;
}

export async function fetchAvailableGenres() {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  let fetchResponse;
  let genres;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/recommendations/available-genre-seeds"
      , {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

    if (fetchResponse.ok) {
      genres = await fetchResponse.json();
      spotifyStore.dispatch(updateAvailableGenres(genres.genres));
      console.log(genres);
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "Could not retrieve list of available genres", mode: "recommendations" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Could not retrieve list of available genres.", mode: "recommendations" }));
    console.log(error);
  }
}

export async function fetchRecommendations() {

  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  let seed_artists = spotifyStore.getState((state) => state.recommendation.providedArtists).recommendation.providedArtists.map((x) => x.id);
  const seed_genres = spotifyStore.getState((state) => state.recommendation.selectedGenres).recommendation.selectedGenres;
  console.log(seed_genres);
  let seed_tracks = spotifyStore.getState((state) => state.recommendation.providedTracks).recommendation.providedTracks.map((x) => x.id);




  let resultData;
  let fetchResponse;
  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/recommendations?limit=50&seed_artists=" + encodeURIComponent(seed_artists.join(","))
      + "&seed_genres=" + encodeURIComponent(seed_genres.join(","))
      + "&seed_tracks=" + encodeURIComponent(seed_tracks.join(",")) + "&min_danceability=" + encodeURIComponent("0.8")
      , {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      });

    if (fetchResponse.ok) {
      resultData = await fetchResponse.json();
      console.log(resultData);
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));
      spotifyStore.dispatch(updateRecommendationResults(resultData));
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "Search for recommendations could not be completed.", mode: "recommendations" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Search for recommendations could not be completed.", mode: "recommendations" }));
    console.log(error);
  }
}

export async function fetchPlaylist(playlistInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (playlistInput == null) {
    spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid playlist URL or ID number.", mode: "playlist" }));
    return;
  }
  let playlistID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(playlistInput) == null) {
    const trackIDRegex = /^([a-zA-Z0-9]{5,})$/;
    const playlistMatch = trackIDRegex.exec(playlistInput);
    if (playlistMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid playlist ID number.", mode: "album" }));
      return;
    } else {
      playlistID = playlistMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const playlistMatch = urlRegex.exec(playlistInput);
    if (playlistMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid playlist URL.", mode: "playlist" }));
      return;
    } else {
      playlistID = playlistMatch[1];
    }
  }

  let playlistData;
  let fetchResponse;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/playlists/" + playlistID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (fetchResponse.ok) {
      playlistData = await fetchResponse.json();
      spotifyStore.dispatch(updatePlaylist(playlistData));
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));
      console.log(playlistData);

    } else {
      spotifyStore.dispatch(updateFetchError({ message: "No playlist matching that URL or ID number could be found.", mode: "playlist" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Sorry, a playlist matching that URL or ID number couldn't be found.", mode: "playlist" }));
    console.log(error);
  }
}

export async function getQuickTrack(trackInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (trackInput == null || trackInput == "") {
    return null;
  }
  let trackID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(trackInput) == null) {
    // it's just a trackID
    const trackIDRegex = /^([a-zA-Z0-9]+)$/;
    const trackMatch = trackIDRegex.exec(trackInput);
    if (trackMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid track ID number.", mode: "track" }));
      return null;
    } else {
      trackID = trackMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const trackMatch = urlRegex.exec(trackInput);
    if (trackMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid track URL.", mode: "track" }));
      return null;
    } else {
      trackID = trackMatch[1];
    }
  }
  let trackData;
  let fetchResponse;
  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/tracks/" + trackID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (fetchResponse.ok) {
      trackData = await fetchResponse.json();
      spotifyStore.dispatch(updateTrackInfo(trackData));
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));

    } else {
      spotifyStore.dispatch(updateFetchError({ message: "No track matching that URL or ID number could be found.", mode: "track" }));
      trackData = null;
      return null;
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Sorry, a track matching that URL or ID number couldn't be found.", mode: "track" }));
    // console.log(error);
    trackData = null;
    return null;
  }
  return trackData;
}

export async function getQuickArtist(artistInput) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (artistInput == null || artistInput == "") {
    return null;
  }
  let artistID = "";

  const isURLRegex = /^https*:/;
  if (isURLRegex.exec(artistInput) == null) {
    // it's just a trackID
    const artistIDRegx = /^([a-zA-Z0-9]+)$/;
    const artistMatch = artistIDRegx.exec(artistInput);
    if (artistMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid artist ID number.", mode: "quick" }));
      return null;
    } else {
      artistID = artistMatch[1];
    }
  } else {
    const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;
    const artistMatch = urlRegex.exec(artistInput);
    if (artistMatch == null) {
      spotifyStore.dispatch(updateFetchError({ message: "Please enter a valid artist URL.", mode: "quick" }));
      return null;
    } else {
      artistID = artistMatch[1];
    }
  }

  let artistData;
  let fetchResponse;
  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/artists/" + artistID, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (fetchResponse.ok) {
      artistData = await fetchResponse.json();
      spotifyStore.dispatch(updateTrackInfo(artistData));
      spotifyStore.dispatch(updateFetchError({ message: "", mode: "" }));

    } else {
      spotifyStore.dispatch(updateFetchError({ message: "No artist matching that URL or ID number could be found.", mode: "quick" }));
      artistData = null;
      return null;
    }

  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "Sorry, an artist matching that URL or ID number couldn't be found.", mode: "quick" }));
    // console.log(error);
    artistData = null;
    return null;
  }
  return artistData;
}