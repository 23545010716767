import React, { useEffect, useState } from "react";
import { TrackInformation } from "./components/TrackInformation";
import {
  fetchTrackFeatures,
  fetchTrackInfo,
} from "./functions/spotify-fetches";
import { useDispatch, useSelector } from "react-redux";
import { updateFetchError } from "./redux/resultSlice";
import { TrackDetailCard, TrackMetaCard } from "./components/TrackInfoCards";
import { TrackFeatureCard } from "./components/TrackInfoCards";
import SingleItemSearchBar from "./components/SingleItemSearchBar";

import "./theme/spotifyPage.css";
import "./theme/displayParts.css";
import DualSearch from "./components/DualSearch";

const TrackSearch = () => {
  const deviceMode = useSelector((state) => state.utility.device);

  const [searchURL, setSearchURL] = useState("");
  const [urlInput, setUrlInput] = useState("");
  const fetchError = useSelector((state) => state.result.fetchError);
  const dispatch = useDispatch();

  const trackMeta = useSelector((state) => state.result.trackMeta);

  const handleInputChange = (e) => {
    setUrlInput(e.target.value);
  };

  const handleSearch = async () => {
    setSearchURL(urlInput);
  };

  useEffect(() => {
    fetchTrackInfo(searchURL);
  }, [searchURL]);

  useEffect(() => {
    fetchTrackFeatures(trackMeta.id);
  }, [trackMeta]);

  useEffect(() => {
    dispatch(updateFetchError({ message: "", mode: "" }));
  }, []);

  return (
    <>
      <div className={"content ".concat(deviceMode)}>
        <div className="grid-chunk">
          {/* <SingleItemSearchBar /> */}
          <DualSearch />
          {trackMeta.id == "" ? <></> : <TrackMetaCard />}
        </div>

        {trackMeta.id == "" ? <></> : <TrackInformation search={searchURL} />}

        <div className="grid-chunk">
          <TrackFeatureCard />
          <TrackDetailCard />
        </div>
      </div>
    </>
  );
};

export default TrackSearch;
