import { useDispatch, useSelector } from "react-redux";
import spotifyStore from "../store";
import { updateExpiry, updateToken } from "../redux/tokenSlice";
import { Buffer } from "buffer";
import { useNavigate } from "react-router";

const redirectUri = process.env.REACT_APP_AUTH_REDIRECT;
const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;

// SECTION General Spotify Access

export function tokenStillValid() {
  const tokenExpiry = spotifyStore.getState((state) => state.token.expiry);
  if (new Date().getTime() >= tokenExpiry) {
    // console.log("Token not valid. Getting new token.");
    return false;
  } else {
    return true;
  }

}

export async function requestToken() {
  const tokenExpiry = spotifyStore.getState((state) => state.token.expiry).token.expiry;
  if (new Date().getTime() >= tokenExpiry) {
    await fetch("https://accounts.spotify.com/api/token", {
      method: "POST",
      headers: {
        Authorization:
          "Basic " + Buffer.from(process.env.REACT_APP_SPOTIFY_CLIENT_ID + ":" + process.env.REACT_APP_SPOTIFY_CLIENT_SECRET).toString("base64"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: "grant_type=client_credentials",
    }).catch((err) => {
      console.log(err);
      return;
    }).then((res) => res.json())
      .then((rjson) => {
        spotifyStore.dispatch(updateExpiry(new Date().getTime() + Number(rjson.expires_in) * 1000));
        spotifyStore.dispatch(updateToken(rjson.access_token));
      })
  }
}


//SECTION - Spotify User Auth

// PART - Utility functions

function generateRandomString(length) {
  let text = '';
  let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

async function generateCodeChallenge(codeVerifier) {
  function base64encode(string) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(string)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  }

  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest('SHA-256', data);

  return base64encode(digest);
}


export async function spotifyLogin() {

  console.log("Getting user authorization")

  let codeVerifier = generateRandomString(128);

  generateCodeChallenge(codeVerifier).then(codeChallenge => {
    let state = generateRandomString(16);
    let scope = 'user-read-private user-read-email user-read-playback-state user-read-currently-playing playlist-read-private playlist-read-collaborative user-follow-read user-read-playback-position user-top-read user-library-read user-read-email user-read-private';

    localStorage.setItem('code_verifier', codeVerifier);

    let args = new URLSearchParams({
      response_type: 'code',
      client_id: clientId,
      scope: scope,
      redirect_uri: redirectUri,
      state: state,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge
    });

    window.location = 'https://accounts.spotify.com/authorize?' + args;
  });

  console.log("Obtained user authorization");
}

export async function requestUserAccessToken() {

  console.log("Requesting user access token");
  let codeVerifier = localStorage.getItem('code_verifier');

  const urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("code");

  let body = new URLSearchParams({
    grant_type: 'authorization_code',
    code: code,
    redirect_uri: redirectUri,
    client_id: clientId,
    code_verifier: codeVerifier
  });

  const response = await fetch('https://accounts.spotify.com/api/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: body
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('HTTP status ' + response.status);
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem('access_token', data.access_token);
    })
    .catch(error => {
      console.error('Error:', error);
    });

  if (localStorage.getItem('access_token') == null) {
    return false;
  } else {
    return true;
  }
}

export async function refreshAccessCode() {
  let refreshToken = localStorage.getItem("refesh_token");

  let body = new URLSearchParams({
    grant_type: 'refresh_token',
    refesh_token: refreshToken,
    client_id: clientId,
  });

  const response = await fetch('https://accounts.spotify.com/api/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: body
  })
    .then(response => {
      if (!response.ok) {
        throw new Error('HTTP status ' + response.status);
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem('access_token', data.access_token);
    })
    .catch(error => {
      console.error('Error:', error);
    });


}
