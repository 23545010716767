import React, { useEffect, useState } from "react";

import { getMedianChart, getTrackColor } from "../functions/spotify-utility";
import { fetchTopUserTracks } from "../functions/user-fetches";
import { useDispatch, useSelector } from "react-redux";
import { updateMode } from "../redux/searchSlice";
import { fetchMultipleTrackFeatures } from "../functions/spotify-fetches";
import TrackRadarChart from "../components/TrackRadarChart";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  // speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

const UserShape = () => {
  const dispatch = useDispatch();
  const topTracks = useSelector((state) => state.user.topTracks);
  const deviceMode = useSelector((state) => state.utility.device);

  const [showTopTracks, setShowTopTracks] = useState(deviceMode == "mobile");
  const [timeRange, setTimeRange] = useState("medium_term");
  const [collectiveRadarData, setCollectiveRadarData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        // speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000", index: 0 },
    },
  ]);
  const [medianData, setMedianData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        // speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000" },
    },
  ]);

  async function changeTimeRange(term) {
    if (term != timeRange) {
      await fetchTopUserTracks(term);
      setTimeRange(term);
    }
  }

  async function processCollectiveData() {
    dispatch(updateMode("user"));
    let tracksList = topTracks.items.map((x) => x.id);
    let trackFeatures = await fetchMultipleTrackFeatures(tracksList);

    let newData = [];
    for (let i = 0; i < trackFeatures.audio_features.length; i++) {
      if (trackFeatures.audio_features[i] != null) {
        newData.push({
          data: {
            acousticness: trackFeatures.audio_features[i].acousticness,
            danceability: trackFeatures.audio_features[i].danceability,
            energy: trackFeatures.audio_features[i].energy,
            instrumentalness: trackFeatures.audio_features[i].instrumentalness,
            liveness: trackFeatures.audio_features[i].liveness,
            // speechiness: trackFeatures.audio_features[i].speechiness,
            valence: trackFeatures.audio_features[i].valence,
          },
          meta: {
            color: getTrackColor(trackFeatures.audio_features[i]),
            index: i,
          },
        });
      }
    }
    let medianData = getMedianChart(newData);
    let aveColor = getTrackColor(medianData[0].data);
    setCollectiveRadarData(newData);
    setMedianData(medianData);
  }

  useEffect(() => {
    if (topTracks.items[0].id == "") {
      fetchTopUserTracks(timeRange);
    }
  }, []);

  useEffect(() => {
    processCollectiveData(topTracks);
  }, [topTracks]);

  return (
    <div className={"section user-shape ".concat(deviceMode)}>
      <div>
        <h2>The Shape of Your Taste</h2>
        <p>
          Based off of your top 20 tracks{" "}
          <em style={{ fontStyle: "normal", fontWeight: 700 }}>
            {timeRange == "short_term"
              ? "from the last 4 weeks"
              : timeRange == "medium_term"
              ? "from the last 6 months"
              : "of all time (on Spotify)"}
          </em>
        </p>

        <TrackRadarChart
          radardata={collectiveRadarData}
          labels={radarLabels}
          hide={false}
          title={"Characteristics of Individual Tracks"}
        />

        <TrackRadarChart
          radardata={medianData}
          labels={radarLabels}
          hide={false}
          mode={"average"}
          title={"Average Track Characteristics"}
        />

        <p>Want a different time range?</p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          {timeRange != "short_term" ? (
            <button onClick={() => changeTimeRange("short_term")}>
              Last 4 weeks
            </button>
          ) : (
            <></>
          )}
          {timeRange != "medium_term" ? (
            <button onClick={() => changeTimeRange("medium_term")}>
              Last 6 months
            </button>
          ) : (
            <></>
          )}
          {timeRange != "long_term" ? (
            <button onClick={() => changeTimeRange("long_term")}>
              Account lifetime
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      {deviceMode == "mobile" ? (
        <button onClick={() => setShowTopTracks(!showTopTracks)}>
          {showTopTracks ? "Show" : "Hide"} top tracks
        </button>
      ) : (
        <></>
      )}
      <div
        className={"top-tracks "
          .concat(showTopTracks ? "hidden " : "visible ")
          .concat(deviceMode)}
      >
        {topTracks.items.map((track) => {
          return (
            <div key={track.id} className={"top-item ".concat(deviceMode)}>
              <img
                src={track.album.images[track.album.images.length - 1].url}
              ></img>
              <p>
                {track.name} by{" "}
                {track.album.artists.map((x) => x.name).join(", ")}
              </p>
              <p className="id-tag">{track.id}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserShape;
