import React, { useEffect, useState } from "react";

import "../theme/trackInformation.css";
import TrackRadarChart from "./TrackRadarChart";
import {
  TrackMetaCard,
  TrackDetailCard,
  TrackFeatureCard,
} from "./TrackInfoCards";
import TrackFeatures from "./TrackFeatures";
import { useSelector } from "react-redux";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  // speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

export const TrackInformation = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);
  const trackInfo = useSelector((state) => state.result.trackMeta);
  const trackFeatureState = useSelector((state) => state.result.trackFeatures);

  const [radarLabels, setRadarLabels] = useState({
    danceability: "Danceable",
    energy: "Energy",
    valence: "Valence",
    instrumentalness: "Instrumental",
    // speechiness: "Speechy",
    acousticness: "Acoustic",
    liveness: "Liveness",
  });
  const [hide, setHide] = useState(false);

  useEffect(() => {
    if (trackInfo.id != "") {
      setHide(false);
    } else {
      setHide(true);
    }
  }, [trackInfo]);

  const [radarData, setRadarData] = useState([
    {
      data: {
        energy: 0.0,
        acousticness: 0.0,
        danceability: 0.0,
        liveness: 0.0,
        instrumentalness: 0.0,
        speechiness: 0.0,
        valence: 0.0,
      },
      meta: { color: "#000" },
    },
  ]);

  function getTrackColor(featuresVals) {
    let val1 = featuresVals.energy - featuresVals.acousticness;
    let val2 = featuresVals.danceability - featuresVals.instrumentalness;
    let val3 = featuresVals.liveness - featuresVals.valence;
    let tempVals = [
      (1.0 - val1) * 16 * 16,
      (1.0 - 2 * Math.abs(0.5 - val2)) * 16 * 16,
      val3 * 16 * 16,
    ];

    let seg1 = Math.ceil(tempVals[0] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg1.length < 2) {
      seg1 = "0" + seg1;
    }

    let seg2 = Math.ceil(tempVals[1] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg2.length < 2) {
      seg2 = "0" + seg2;
    }
    let seg3 = Math.ceil(tempVals[2] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg3.length < 2) {
      seg3 = "0" + seg3;
    }
    return "#" + seg1 + seg2 + seg3;
  }

  useEffect(() => {
    updateRadarData();
  }, [trackFeatureState]);

  function updateRadarData() {
    var color = getTrackColor(trackFeatureState);
    if (typeof trackFeatureState.energy != "undefined") {
      setRadarData([
        {
          data: {
            energy: trackFeatureState.energy,
            acousticness: trackFeatureState.acousticness,
            danceability: trackFeatureState.danceability,
            liveness: trackFeatureState.liveness,
            instrumentalness: trackFeatureState.instrumentalness,
            valence: trackFeatureState.valence,
          },
          meta: { color: color },
        },
      ]);
    }
    setRadarLabels({
      danceability:
        (trackFeatureState.danceability * 100 >= 0.01
          ? (trackFeatureState.danceability * 100).toPrecision(3)
          : "0.0") + "% Danceable",
      energy:
        (trackFeatureState.energy * 100 >= 0.01
          ? (trackFeatureState.energy * 100).toPrecision(3)
          : "0.0") + "% Energy",
      valence:
        (trackFeatureState.valence * 100 >= 0.01
          ? (trackFeatureState.valence * 100).toPrecision(3)
          : "0.0") + "% Valence",
      instrumentalness:
        (trackFeatureState.instrumentalness * 100 >= 0.01
          ? (trackFeatureState.instrumentalness * 100).toPrecision(3)
          : "0.0") + "% Instrumental",
      // speechiness: "Speechy",
      acousticness:
        (trackFeatureState.acousticness * 100 >= 0.01
          ? (trackFeatureState.acousticness * 100).toPrecision(3)
          : "0.0") + "% Acoustic",
      liveness:
        (trackFeatureState.liveness * 100 >= 0.01
          ? (trackFeatureState.liveness * 100).toPrecision(3)
          : "0.0") + "% Liveness",
    });
  }

  return (
    <div
      className={"section ".concat(deviceMode)}
      style={trackInfo.id == "" ? { display: "hidden" } : {}}
    >
      <TrackRadarChart
        radardata={radarData}
        labels={radarLabels}
        hide={hide}
        title={"Track Characteristics"}
      />
    </div>
  );
};
