import { requestToken } from "./spotify-auth";
import spotifyStore from "../store";
import { updateFetchError } from "../redux/resultSlice";

export async function getMarkets() {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;



  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/markets", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "markets" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "markets" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}



export async function getBrowseCategories(market) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (market == "") {
    return;
  }


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/browse/categories?country=" + encodeURIComponent(market), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "markets" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "markets" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}

export async function getCategoryPlaylists(categoryID, market) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (categoryID == "") {
    return;
  }


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/browse/categories/" + encodeURIComponent(categoryID) + "/playlists?country=" + encodeURIComponent(market) + "&limit=50", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      if (fetchResponse.status == 404) {
        return [];
      } else {
        spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "markets" }));
        throw new Error("HTTP error! status: " + fetchResponse.status);
      }
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "markets" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}
