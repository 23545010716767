import React, { useEffect, useState } from "react";
import {
  getBrowseCategories,
  getCategoryPlaylists,
  getMarkets,
} from "./functions/spotify-browse";
import { useDispatch, useSelector } from "react-redux";
import iso31661Alpha2 from "iso-3166-1-alpha-2";

import "./theme/spotifyPage.css";
import "./theme/displayParts.css";
import { updateMode } from "./redux/searchSlice";
import { fetchPlaylist } from "./functions/spotify-fetches";

const BrowsePage = () => {
  const dispatch = useDispatch();
  const deviceMode = useSelector((state) => state.utility.device);
  const [markets, setMarkets] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState("");
  const [browseResult, setBrowseResult] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedName, setSelectedName] = useState("");
  const [playlists, setPlaylists] = useState([]);

  async function fetchMarkets() {
    let tempMark = [""];
    let loadMark = [];
    loadMark = await getMarkets();
    tempMark = tempMark.concat(loadMark.markets);
    if (typeof tempMark != "undefined") {
      setMarkets(tempMark);
    }
  }

  async function fetchBrowsing() {
    let tempBrowse = [];
    let loadBrowse = [""];
    tempBrowse = await getBrowseCategories(selectedMarket);
    let browseVal = tempBrowse.categories.items.map((x) => x);
    loadBrowse = loadBrowse.concat(browseVal);
    setBrowseResult(() => loadBrowse);
  }

  async function getPlaylists() {
    let tempPls = [];
    tempPls = await getCategoryPlaylists(selectedCategory, selectedMarket);
    let pLVals = tempPls.playlists.items.map((x) => x);
    let parsedVals = [];
    let pInd = [];
    for (let i = 0; i < pLVals.length; i++) {
      if (pLVals[i] != null && pInd.indexOf(pLVals[i].id) < 0) {
        parsedVals.push(pLVals[i]);
        pInd.push(pLVals[i].id);
      }
    }

    setPlaylists(parsedVals);
  }

  async function goToPlaylist(idTag) {
    await fetchPlaylist(idTag);
    dispatch(updateMode("playlist"));
  }

  useEffect(() => {
    if (selectedMarket != "") {
      setSelectedCategory(() => "");
      fetchBrowsing();
    }
  }, [selectedMarket]);

  useEffect(() => {
    if (selectedCategory != "") {
      getPlaylists();
    }
  }, [selectedCategory]);

  useEffect(() => {
    fetchMarkets();
  }, []);

  return (
    <div className={"content ".concat(deviceMode)}>
      <div className={"section ".concat(deviceMode)}>
        <div className="grid-chunk">
          <h2>Country</h2>
          <select
            onChange={(e) => setSelectedMarket(() => e.target.value)}
            placeholder="Select country"
            style={{ width: "fit-content", maxWidth: "300px" }}
          >
            {markets.map((x) => {
              return (
                <option key={x} value={x}>
                  {x} {iso31661Alpha2.getCountry(x)}
                </option>
              );
            })}
          </select>
        </div>

        {selectedMarket == "" ? (
          <></>
        ) : (
          <>
            <h3>Category</h3>
            <select
              style={
                browseResult.length > 1
                  ? { width: "fit-content", maxWidth: "300px" }
                  : { display: "none" }
              }
              onChange={(e) => {
                setSelectedCategory(() => e.target.value);
              }}
            >
              {browseResult.map((x) => {
                return (
                  <option key={x.name} value={x.id} id={x.name}>
                    {x.name}
                  </option>
                );
              })}
            </select>
          </>
        )}
      </div>
      <div className={"section ".concat(deviceMode)}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          {playlists.map((x) => {
            if (x != null) {
              return (
                <div
                  key={x.id}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    flexShrink: 1,
                    margin: "1ex",
                    background: "var(--background-white)",
                    borderRadius: "var(--section-radius)",
                    boxShadow: "var(--content-shadow)",
                    padding: "0.5ex 1ex",
                    textAlign: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    goToPlaylist(x.id);
                  }}
                >
                  <img
                    src={
                      x.images[0] == null
                        ? ""
                        : x.images[x.images.length - 1].url
                    }
                    style={{
                      maxWidth: "80px",
                      maxHeight: "80px",
                      borderRadius: "var(--section-radius)",
                      objectFit: "cover",
                      margin: "0.5ex",
                      marginTop: "1ex",
                    }}
                  ></img>
                  <p style={{ maxWidth: "120px", marginBottom: "0.5ex" }}>
                    {x.name}
                  </p>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default BrowsePage;
