import { createSlice } from "@reduxjs/toolkit";

export const recommendationSlice = createSlice({
  name: "recommendation",
  initialState: {
    availableGenres: [],
    selectedGenres: [],
    providedTracks: [],
    providedArtists: [],
    genreSeedError: "",
    seedCountError: "",
    results: {
      seeds: [
        {
          afterFilteringSize: 0,
          afterRelinkingSize: 0,
          href: "",
          id: "",
          initialPoolSize: 0,
          type: "",
        },
      ],
      tracks: [
        {
          album: {
            album_type: "",
            total_tracks: 0,
            available_markets: [],
            external_urls: {
              spotify: "",
            },
            href: "",
            id: "",
            images: [
              {
                url: "",
                height: 0,
                width: 0,
              },
            ],
            name: "",
            release_date: "",
            release_date_precision: "",
            restrictions: {
              reason: "",
            },
            type: "",
            uri: "",
            copyrights: [
              {
                text: "",
                type: "",
              },
            ],
            external_ids: {
              isrc: "",
              ean: "",
              upc: "",
            },
            genres: [""],
            label: "",
            popularity: 0,
            album_group: "",
            artists: [
              {
                external_urls: {
                  spotify: "",
                },
                href: "",
                id: "",
                name: "",
                type: "artist",
                uri: "",
              },
            ],
          },
          artists: [
            {
              external_urls: {
                spotify: "",
              },
              followers: {
                href: "",
                total: 0,
              },
              genres: [],
              href: "",
              id: "",
              images: [
                {
                  url: "",
                  height: 0,
                  width: 0,
                },
              ],
              name: "",
              popularity: 0,
              type: "",
              uri: "",
            },
          ],
          available_markets: [],
          disc_number: 0,
          duration_ms: 0,
          explicit: false,
          external_ids: {
            isrc: "",
            ean: "",
            upc: "",
          },
          external_urls: {
            spotify: "",
          },
          href: "",
          id: "",
          is_playable: false,
          linked_from: {},
          restrictions: {
            reason: "",
          },
          name: "",
          popularity: 0,
          preview_url: "",
          track_number: 0,
          type: "",
          uri: "",
          is_local: false,
        },
      ],
    },
  },
  reducers: {
    updateAvailableGenres: (state, action) => {
      state.availableGenres = action.payload;
    },
    updateSelectedGenres: (state, action) => {
      state.selectedGenres = action.payload;
    },
    clearGenreSeedError: (state) => {
      state.genreSeedError = "";
    },
    updateGenreSeedError: (state, action) => {
      state.genreSeedError = action.payload;
    },
    updateRecommendationResults: (state, action) => {
      state.results = action.payload;
    },
    updateProvidedTracks: (state, action) => {
      state.providedTracks = action.payload;
    },
    updateSeedCountError: (state, action) => {
      state.seedCountError = action.payload;
    },
    clearSeedCountError: (state) => {
      state.seedCountError = "";
    },
    updateProvidedArtists: (state, action) => {
      state.providedArtists = action.payload;
    },
  },
});

export const {
  updateAvailableGenres,
  updateSelectedGenres,
  clearGenreSeedError,
  updateGenreSeedError,
  updateRecommendationResults,
  updateProvidedTracks,
  updateSeedCountError,
  clearSeedCountError,
  updateProvidedArtists,
} = recommendationSlice.actions;

export default recommendationSlice.reducer;
