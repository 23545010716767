import React, { useEffect, useState } from "react";
import "./theme/spotifyPage.css";
import "./theme/interaction.css";
import { Provider, useDispatch, useSelector } from "react-redux";
import spotifyStore from "./store";
import TrackSearch from "./TrackSearch";
import ArtistSearch from "./ArtistSearch";
import { updateMode } from "./redux/searchSlice";
import GeneralSearch from "./GeneralSearch";
import { AlbumSearch } from "./AlbumSearch";
import Recommendations from "./Recommendations";
import { PlaylistSearch } from "./PlaylistSearch";
import Login from "./userPages/Login";
import { useNavigate } from "react-router";
import UserHome from "./userPages/UserHome";
import { pageNames } from "./components/Enums";

import { ReactComponent as HomeIcon } from "./assets/user.svg";
import { ReactComponent as TrackIcon } from "./assets/track.svg";
import { ReactComponent as AlbumIcon } from "./assets/album.svg";
import { ReactComponent as ArtistIcon } from "./assets/artist.svg";
import { ReactComponent as RecommendationIcon } from "./assets/recommendation.svg";
import BrowsePage from "./BrowsePage";

const SpotifyPage = () => {
  const deviceMode = useSelector((state) => state.utility.device);

  const searchModeState = useSelector((state) => state.search.mode);
  const [currentPage, setCurrentPage] = useState(<TrackSearch />);

  const [searchMode, setSearchMode] = useState("user");
  const [showMenu, setShowMenu] = useState(deviceMode == "desktop");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    switch (searchModeState) {
      case "track":
        setCurrentPage(<TrackSearch />);
        break;
      case "artist":
        setCurrentPage(<ArtistSearch />);
        break;
      case "album":
        setCurrentPage(<AlbumSearch />);
        break;
      case "playlist":
        setCurrentPage(<PlaylistSearch />);
        break;
      case "recommendation":
        setCurrentPage(<Recommendations />);
        break;
      case "login":
        setCurrentPage(<Login />);
        navigate("/spotify-tool/login");
        break;
      case "user":
        setCurrentPage(<UserHome />);
        break;
      case "browse":
        setCurrentPage(<BrowsePage />);
        break;
      default:
        break;
    }
  }, [searchModeState]);

  return (
    <>
      <div className="spotify-page">
        <div className={"main-menu ".concat(deviceMode)}>
          <div
            className={"tab-toggle-bar "
              .concat(deviceMode)
              .concat(showMenu ? " visible" : " hidden")}
          >
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "user" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("user");
                dispatch(updateMode("user"));
              }}
            >
              <div className="tab-icon">
                <HomeIcon />
                {pageNames["user"]}
              </div>
            </button>
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "track" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("track");
                dispatch(updateMode("track"));
              }}
            >
              {pageNames["track"]}
            </button>
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "artist" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("artist");
                dispatch(updateMode("artist"));
              }}
            >
              {pageNames["artist"]}
            </button>{" "}
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "album" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("album");
                dispatch(updateMode("album"));
              }}
            >
              {pageNames["album"]}
            </button>
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "playlist" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("playlist");
                dispatch(updateMode("playlist"));
              }}
            >
              {pageNames["playlist"]}
            </button>
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "recommendation" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("recommendation");
                dispatch(updateMode("recommendation"));
              }}
            >
              {pageNames["recommendation"]}
            </button>
            <button
              className={"tab-toggle "
                .concat(deviceMode + " ")
                .concat(searchMode == "browse" ? "active" : "inactive")}
              onClick={() => {
                setSearchMode("browse");
                dispatch(updateMode("browse"));
              }}
            >
              {pageNames["browse"]}
            </button>
          </div>
          {deviceMode == "desktop" ? (
            <></>
          ) : (
            <button onClick={() => setShowMenu(!showMenu)}>
              {showMenu ? "Hide Menu" : "Show Menu"}
            </button>
          )}
        </div>
        <h1 className={"page-title ".concat(deviceMode)}>
          {pageNames[searchMode]}
        </h1>
        {currentPage}
      </div>
      <div
        style={{
          position: "fixed",
          zIndex: 10,
          bottom: 0,
          left: 0,
          right: 0,
          margin: 0,
          padding: 0,
          background: "black",
        }}
      >
        <a
          href="https://github.com/brooke-k"
          style={{
            color: "grey",
            fontSize: "11pt",
            padding: 0,
            margin: 0,
            textAlign: "right",
            textDecoration: "none",
          }}
        >
          <p
            style={{
              color: "grey",
              fontSize: "11pt",
              padding: "5px",
              margin: 0,
              textAlign: "right",
              textDecoration: "none",
            }}
          >
            made by brooke kindleman
          </p>
        </a>
      </div>
    </>
  );
};

export default SpotifyPage;
