import spotifyStore from "../store";
import { updateFetchError } from "../redux/resultSlice";
import { requestToken } from "./spotify-auth";


export async function searchForTrack(searchQuery) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (searchQuery == null) {
    return [];
  } else if (searchQuery == "") {
    return [];
  }
  let searchTerm = encodeURIComponent(searchQuery);


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/search?q=" + searchTerm + "&type=" + encodeURIComponent("track") + "&limit=10", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}

export async function searchForArtist(searchQuery) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (searchQuery == null) {
    return [];
  } else if (searchQuery == "") {
    return [];
  }
  let searchTerm = encodeURIComponent(searchQuery);


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/search?q=" + searchTerm + "&type=" + encodeURIComponent("artist") + "&limit=10", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}

export async function searchForAlbum(searchQuery) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (searchQuery == null) {
    return [];
  } else if (searchQuery == "") {
    return [];
  }
  let searchTerm = encodeURIComponent(searchQuery);


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/search?q=" + searchTerm + "&type=" + encodeURIComponent("album") + "&limit=10", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}

export async function searchForPlaylist(searchQuery) {
  await requestToken();

  const token = spotifyStore.getState((state) => state.token.token).token.token;

  if (searchQuery == null) {
    return [];
  } else if (searchQuery == "") {
    return [];
  }
  let searchTerm = encodeURIComponent(searchQuery);


  let fetchResponse;
  let searchResult;

  try {
    fetchResponse = await fetch("https://api.spotify.com/v1/search?q=" + searchTerm + "&type=" + encodeURIComponent("playlist") + "&limit=10", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
    if (fetchResponse.ok) {
      searchResult = await fetchResponse.json();
      return searchResult;
    } else {
      spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
      throw new Error("HTTP error! status: " + fetchResponse.status);
    }
  } catch (error) {
    spotifyStore.dispatch(updateFetchError({ message: "The requested search could not be completed.", mode: "search" }));
    throw new Error("HTTP error! status: " + fetchResponse.status);
  }

}
