import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAvailableGenres,
  fetchMultipleTrackFeatures,
  fetchRecommendations,
  getQuickArtist,
  getQuickTrack,
} from "./functions/spotify-fetches";
import {
  clearGenreSeedError,
  updateGenreSeedError,
  updateProvidedArtists,
  updateProvidedTracks,
  updateSeedCountError,
  updateSelectedGenres,
} from "./redux/recommendationSlice";
import { updateFetchError } from "./redux/resultSlice";
import TrackRadarChart from "./components/TrackRadarChart";
import QuickTrackList from "./components/QuickTrackList";

import "./theme/search.css";
import "./theme/interaction.css";
import { parseUrlId } from "./functions/spotify-utility";
import IdTag from "./components/IdTag";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  // speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

function getTrackColor(featuresVals) {
  if (typeof featuresVals == "undefined") {
    return "#0000";
  }
  if (featuresVals == null) {
    return "#0000";
  }
  let val1 = featuresVals.energy - featuresVals.acousticness;
  let val2 = featuresVals.danceability - featuresVals.instrumentalness;
  let val3 = featuresVals.liveness - featuresVals.valence;
  let tempVals = [
    (1.0 - val1) * 16 * 16,
    (1.0 - 2 * Math.abs(0.5 - val2)) * 16 * 16,
    val3 * 16 * 16,
  ];

  let seg1 = Math.ceil(tempVals[0] * 16 * 16)
    .toString(16)
    .slice(-2);
  if (seg1.length < 2) {
    seg1 = "0" + seg1;
  }

  let seg2 = Math.ceil(tempVals[1] * 16 * 16)
    .toString(16)
    .slice(-2);
  if (seg2.length < 2) {
    seg2 = "0" + seg2;
  }
  let seg3 = Math.ceil(tempVals[2] * 16 * 16)
    .toString(16)
    .slice(-2);
  if (seg3.length < 2) {
    seg3 = "0" + seg3;
  }
  return "#" + seg1 + seg2 + seg3;
}

const Recommendations = () => {
  const deviceMode = useSelector((state) => state.utility.device);

  const fetchError = useSelector((state) => state.result.fetchError);
  const availableGenres = useSelector(
    (state) => state.recommendation.availableGenres
  );
  const genreSeedError = useSelector(
    (state) => state.recommendation.genreSeedError
  );
  const selectedGenres = useSelector(
    (state) => state.recommendation.selectedGenres
  );
  const providedTracks = useSelector(
    (state) => state.recommendation.providedTracks
  );
  const providedArtists = useSelector(
    (state) => state.recommendation.providedArtists
  );
  const seedCountError = useSelector(
    (state) => state.recommendation.seedCountError
  );

  const [inputError, setInputError] = useState("");

  const recommendations = useSelector((state) => state.recommendation.results);

  const [radarList, setRadarList] = useState([
    [
      {
        data: {
          energy: 0.0,
          acousticness: 0.0,
          danceability: 0.0,
          liveness: 0.0,
          instrumentalness: 0.0,
          // speechiness: 0.0,
          valence: 0.0,
        },
        meta: { color: "#000" },
        index: 0,
      },
    ],
  ]);

  const [trackInput, setTrackInput] = useState("");
  const [artistInput, setArtistInput] = useState("");
  const [hideGenres, setHideGenres] = useState(true);

  const dispatch = useDispatch();

  function removeAllCriteria() {
    dispatch(updateProvidedArtists([]));
    dispatch(updateProvidedTracks([]));
    dispatch(updateSelectedGenres([]));
  }

  async function getRadarData() {
    var newData = [];
    const trackData = await fetchMultipleTrackFeatures(
      recommendations.tracks.map((x) => x.id)
    );
    if (trackData.audio_features.length > 0) {
      for (let i = 0; i < trackData.audio_features.length; i++) {
        if (trackData.audio_features[i] != null) {
          newData.push([
            {
              data: {
                energy: trackData.audio_features[i].energy,
                acousticness: trackData.audio_features[i].acousticness,
                danceability: trackData.audio_features[i].danceability,
                liveness: trackData.audio_features[i].liveness,
                instrumentalness: trackData.audio_features[i].instrumentalness,
                speechiness: trackData.audio_features[i].speechiness,
                valence: trackData.audio_features[i].valence,
              },
              meta: {
                color: getTrackColor(trackData.audio_features[i]),
              },
              index: i,
            },
          ]);
        } else {
          newData.push([
            {
              data: {
                energy: 0.0,
                acousticness: 0.0,
                danceability: 0.0,
                liveness: 0.0,
                instrumentalness: 0.0,
                speechiness: 0.0,
                valence: 0.0,
              },
              meta: {
                color: "#0000",
              },
              index: i,
            },
          ]);
        }
      }
    }
    setRadarList(newData);
    console.log(newData);
  }

  function getYear(releaseDate) {
    const yearRegex = /[0-9]{4}/;
    const yearMatch = yearRegex.exec(releaseDate);
    if (yearMatch != null) {
      return yearMatch[0];
    } else {
      return "";
    }
  }

  useEffect(() => {
    checkNumSeeds();
  }, [selectedGenres, providedArtists, providedTracks]);

  useEffect(() => {
    getRadarData();
  }, [recommendations]);

  useEffect(() => {
    fetchAvailableGenres();
    if (selectedGenres.length < 5) {
      dispatch(clearGenreSeedError());
    }
  }, []);

  const addGenre = (e) => {
    if (seedCountError == "") {
      if (selectedGenres.indexOf(e.target.value) < 0) {
        dispatch(updateSelectedGenres(selectedGenres.concat([e.target.value])));
        if (selectedGenres.length == 4) {
          dispatch(
            updateGenreSeedError("Maximum number of genres (5) selected.")
          );
        }
      }
    }
  };

  const removeGenre = (e) => {
    let foundIndex = selectedGenres.indexOf(e.target.value);
    console.log(foundIndex);
    dispatch(clearGenreSeedError());
    if (foundIndex > -1) {
      let newSelected = selectedGenres.map((x) => x);
      newSelected.splice(foundIndex, 1);
      dispatch(updateSelectedGenres(newSelected));
    }
  };

  function checkNumSeeds() {
    var seedCount =
      selectedGenres.length + providedTracks.length + providedArtists.length;
    console.log("Seed count: " + seedCount);
    if (seedCount >= 5) {
      dispatch(updateSeedCountError("Maximum number of seeds (5) provided."));
    } else {
      dispatch(updateSeedCountError(""));
    }
  }

  async function addSelectedArtist(e) {
    if (seedCountError == "" && e.keyCode == 13) {
      if (e.target.value == "") {
        setInputError("Please enter an artist URL or ID number.");
        return;
      }
      let artistID = e.target.value;
      let idsList = providedArtists.map((x) => x.id);
      if (idsList.indexOf(artistID) > -1) {
        setInputError("That artist has already been added.");
        setArtistInput("");
        return;
      } else {
        let artistResult = await getQuickArtist(artistInput);
        if (artistResult == null) {
          setInputError(
            "An artist with that URL or ID number could not be found."
          );
          setArtistInput("");
          return;
        } else {
          let artistsList = providedArtists.map((x) => x);
          artistsList.push(artistResult);
          dispatch(updateProvidedArtists(artistsList));
          setArtistInput("");
          setInputError(artistResult.name + " added");
          return;
        }
      }
    }
  }

  function removeSelectedArtist(e) {
    var idsList = providedArtists.map((x) => x.id);
    let index = idsList.indexOf(e.target.name);
    if (index > -1) {
      let artistsList = providedArtists.map((x) => x);
      setInputError("Removed artist " + artistsList[index].name);
      artistsList.splice(index, 1);
      dispatch(updateProvidedArtists(artistsList));
    }
  }

  function removeSelectedTrack(e) {
    var idsList = providedTracks.map((x) => x.id);
    let index = idsList.indexOf(e.target.name);
    if (index > -1) {
      let tracksList = providedTracks.map((x) => x);
      setInputError("Removed track " + tracksList[index].name);
      tracksList.splice(index, 1);
      dispatch(updateProvidedTracks(tracksList));
    }
  }

  async function addSelectedTrack(e) {
    if (seedCountError == "" && e.keyCode == 13) {
      if (e.target.value == "") {
        setInputError("Please enter a track URL or ID number.");
        return;
      }
      let trackID = e.target.value;
      let idsList = providedTracks.map((x) => x.id);
      if (idsList.indexOf(trackID) > -1) {
        setInputError("That track has already been added.");
        setTrackInput("");
        return;
      } else {
        let trackResult = await getQuickTrack(trackInput);
        if (trackResult == null) {
          setInputError(
            "A track with that URL or ID number could not be found."
          );
          setTrackInput("");
          return;
        } else {
          let tracksList = providedTracks.map((x) => x);
          tracksList.push(trackResult);
          dispatch(updateProvidedTracks(tracksList));
          setTrackInput("");
          setInputError(trackResult.name + " added");
          return;
        }
      }
    }
  }

  function getTrackColor(featuresVals) {
    if (typeof featuresVals == "undefined") {
      return "#0000";
    }
    if (featuresVals == null) {
      return "#0000";
    }
    let val1 = featuresVals.energy - featuresVals.acousticness;
    let val2 = featuresVals.danceability - featuresVals.instrumentalness;
    let val3 = featuresVals.liveness - featuresVals.valence;
    let tempVals = [
      (1.0 - val1) * 16 * 16,
      (1.0 - 2 * Math.abs(0.5 - val2)) * 16 * 16,
      val3 * 16 * 16,
    ];

    let seg1 = Math.ceil(tempVals[0] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg1.length < 2) {
      seg1 = "0" + seg1;
    }

    let seg2 = Math.ceil(tempVals[1] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg2.length < 2) {
      seg2 = "0" + seg2;
    }
    let seg3 = Math.ceil(tempVals[2] * 16 * 16)
      .toString(16)
      .slice(-2);
    if (seg3.length < 2) {
      seg3 = "0" + seg3;
    }
    return "#" + seg1 + seg2 + seg3;
  }

  return (
    <>
      <div className={"content ".concat(deviceMode)}>
        <div className={"section ".concat(deviceMode)}>
          <button
            className="primary"
            onClick={() => {
              fetchRecommendations();
              setHideGenres(true);
              setInputError("");
            }}
          >
            Search
          </button>
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <input
                type="text"
                id="trackSeed"
                name="trackSeed"
                value={trackInput}
                onChange={(e) => setTrackInput(e.target.value)}
                onKeyUp={addSelectedTrack}
                placeholder="Add track URL or ID number"
              ></input>
              <button
                className="secondary"
                onClick={(e) =>
                  addSelectedTrack({
                    keyCode: 13,
                    target: { value: trackInput },
                  })
                }
              >
                +
              </button>
            </div>
            <div>
              <input
                type="text"
                id="artistSeed"
                name="artistSeed"
                value={artistInput}
                onChange={(e) => setArtistInput(e.target.value)}
                onKeyUp={addSelectedArtist}
                placeholder="Add artist URL or ID number"
              ></input>
              <button
                className="secondary"
                onClick={(e) =>
                  addSelectedArtist({
                    keyCode: 13,
                    target: { value: artistInput },
                  })
                }
              >
                +
              </button>
            </div>
          </div>
          <div
            style={{
              alignSelf: "center",
              justifySelf: "center",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              flexGrow: 1,
              flexShrink: 0,
              display: "flex",
              flexDirection: "column",
              padding: "1em",
            }}
          >
            <p style={{ flexGrow: 0, flexShrink: 0 }}>{seedCountError}</p>
            <p style={{ flexGrow: 0, flexShrink: 0 }}>{inputError}</p>
          </div>
        </div>

        <div
          className={"section ".concat(deviceMode)}
          style={{
            display: "flex",
            alignSelf: "start",
            minWidth: "50%",
          }}
        >
          <h2>Current Criteria</h2>

          <ul className="attributes" style={{ listStyle: "none" }}>
            {selectedGenres.map((key) => {
              return (
                <li key={key} className="listed-attribute genre">
                  <p>{key}</p>
                  <button key={key} value={key} onClick={(e) => removeGenre(e)}>
                    X
                  </button>
                </li>
              );
            })}

            {providedTracks.map((x) => {
              return (
                <li key={x.id} className="listed-attribute track">
                  <img
                    style={{ height: "30px", width: "30px" }}
                    src={x.album.images[x.album.images.length - 1].url}
                  ></img>
                  <p>{x.name}</p>
                  <button
                    name={x.id}
                    className="remove"
                    onClick={removeSelectedTrack}
                  >
                    X
                  </button>
                </li>
              );
            })}
            {providedArtists.map((x) => {
              return (
                <li key={x.id} className="listed-attribute artist">
                  <img
                    style={{
                      height: "30px",
                      width: "30px",
                      objectFit: "cover",
                    }}
                    src={x.images[x.images.length - 1].url}
                  ></img>
                  <p>{x.name}</p>
                  <button
                    name={x.id}
                    className="remove"
                    onClick={removeSelectedArtist}
                  >
                    X
                  </button>
                </li>
              );
            })}
          </ul>
          <button className="primary" onClick={() => removeAllCriteria()}>
            Remove All
          </button>
        </div>
        <div
          className={"section ".concat(deviceMode)}
          style={{ flexDirection: "column", width: "100%" }}
        >
          {hideGenres ? (
            <>
              <h2>Available Genres</h2>
              <button
                className="secondary"
                onClick={() => setHideGenres(!hideGenres)}
              >
                {hideGenres ? "Show genres list" : "Hide genres list"}
              </button>
            </>
          ) : (
            <>
              <button
                className="secondary"
                onClick={() => setHideGenres(!hideGenres)}
              >
                {hideGenres ? "Show genres list" : "Hide genres list"}
              </button>
              <h2>Available Genres</h2>

              <ul
                style={
                  seedCountError.genre != ""
                    ? { listStyle: "none" }
                    : { listStyle: "none", opacity: "50%" }
                }
              >
                {availableGenres.map((key) => {
                  return selectedGenres.indexOf(key) < 0 ? (
                    <button
                      className="ternary"
                      key={key}
                      value={key}
                      disabled={seedCountError != ""}
                      onClick={(e) => addGenre(e)}
                    >
                      {key}
                    </button>
                  ) : (
                    <div key={key} style={{ display: "none" }}></div>
                  );
                })}
              </ul>
            </>
          )}
        </div>
        <div
          className={"section ".concat(deviceMode)}
          style={recommendations.tracks[0].id == "" ? { display: "none" } : {}}
        >
          <p>
            Matching {recommendations.tracks.length} (of 50 possible) tracks
            found
          </p>
          {recommendations.tracks.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Track</th>
                  <th>Track ID</th>
                  <th>Artist(s)</th>
                  <th>Album</th>
                </tr>
              </thead>
              <tbody>
                {radarList.map((data) => {
                  return (
                    <tr>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "stretch",
                            alignContent: "center",
                            alignItems: "center",
                            flexGrow: 1,
                            flexShrink: 1,
                            maxWidth: "30em",
                          }}
                        >
                          <img
                            style={{ margin: "0.25em 0.5em" }}
                            src={
                              recommendations.tracks[data[0].index].album
                                .images[
                                recommendations.tracks[data[0].index].album
                                  .images.length - 1
                              ].url
                            }
                          />
                          <p style={{ margin: "auto 1em" }}>
                            {recommendations.tracks[data[0].index].name}
                          </p>
                        </div>
                      </td>
                      <td
                        style={{
                          backgroundColor: data[0].meta.color.concat("80"),
                        }}
                      >
                        <IdTag
                          tag={recommendations.tracks[data[0].index].id}
                          type={"track"}
                        />
                      </td>
                      <td>
                        {recommendations.tracks[data[0].index].artists.map(
                          (x) => {
                            return (
                              <div className="name-id">
                                <p>{x.name}</p>
                                <IdTag tag={x.id} type={"artist"} />
                              </div>
                            );
                          }
                        )}
                      </td>
                      <td>
                        <div className="name-id">
                          <p>
                            {recommendations.tracks[data[0].index].album.name} (
                            {getYear(
                              recommendations.tracks[data[0].index].album
                                .release_date
                            )}
                            )
                          </p>
                          <IdTag
                            tag={recommendations.tracks[data[0].index].album.id}
                            type={"album"}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>Sorry, no recommendations could be made.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Recommendations;
