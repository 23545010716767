import { toBeEmpty } from "@testing-library/jest-dom/dist/matchers";
import { updateSeedError } from "../redux/recommendationSlice";
import spotifyStore from "../store";
import { fetchTrackFeatures } from "./spotify-fetches";


export function parseUrlId(urlID) {

  const isURLRegex = /^https*:/;
  const urlRegex = /(?<=[a-z]+)\/([a-zA-Z0-9]+)\?/;

  let validID = "";
  if (isURLRegex.exec(urlID) == null) {
    const trackIDRegex = /^([a-zA-Z0-9]{2,})$/;
    const idMatch = trackIDRegex.exec(urlID);
    if (idMatch == null) {
      console.log("The provided value \"" + urlID + "\" is not a valid ID or URL");
    } else {
      validID = idMatch[1];
    }
  } else {
    const urlMatch = urlRegex.exec(urlID);
    if (urlMatch == null) {
      console.log("The provided value \"" + urlID + "\" is not a valid ID or URL");
    } else {
      validID = urlMatch[1];
    }
  }
  return validID;
}
export function getTrackColor(featuresVals) {
  if (typeof featuresVals == "undefined") {
    return "#0000";
  }
  if (featuresVals == null) {
    return "#0000";
  }
  let val1 = featuresVals.energy - featuresVals.acousticness;
  let val2 = featuresVals.danceability - featuresVals.instrumentalness;
  let val3 = featuresVals.liveness - featuresVals.valence;
  let tempVals = [
    (1.0 - val1) * 16 * 16,
    (1.0 - 2 * Math.abs(0.5 - val2)) * 16 * 16,
    val3 * 16 * 16,
  ];

  let seg1 = Math.ceil(tempVals[0] * 16 * 16)
    .toString(16)
    .slice(-2);
  if (seg1.length < 2) {
    seg1 = "0" + seg1;
  }

  let seg2 = Math.ceil(tempVals[1] * 16 * 16)
    .toString(16)
    .slice(-2);
  if (seg2.length < 2) {
    seg2 = "0" + seg2;
  }
  let seg3 = Math.ceil(tempVals[2] * 16 * 16)
    .toString(16)
    .slice(-2);
  if (seg3.length < 2) {
    seg3 = "0" + seg3;
  }
  return "#" + seg1 + seg2 + seg3;
}

export function getMedianChart(chartData) {
  let mappedVals = {
    danceability: 0.0,
    energy: 0.0,
    valence: 0.0,
    instrumentalness: 0.0,
    acousticness: 0.0,
    liveness: 0.0,
  };

  mappedVals.danceability = chartData.map((x) => x.data.danceability);
  if (mappedVals.danceability == null) {
    return chartData;
  }
  mappedVals.energy = chartData.map((x) => x.data.energy);
  mappedVals.valence = chartData.map((x) => x.data.valence);
  mappedVals.instrumentalness = chartData.map((x) => x.data.instrumentalness);
  mappedVals.acousticness = chartData.map((x) => x.data.acousticness);
  mappedVals.liveness = chartData.map((x) => x.data.liveness);

  let medianVals = {
    danceability: getMedianVal(mappedVals.danceability),
    energy: getMedianVal(mappedVals.energy),
    valence: getMedianVal(mappedVals.valence),
    instrumentalness: getMedianVal(mappedVals.instrumentalness),
    acousticness: getMedianVal(mappedVals.acousticness),
    liveness: getMedianVal(mappedVals.liveness),
  };

  let meanVals = {
    danceability: getMeanVal(mappedVals.danceability),
    energy: getMeanVal(mappedVals.energy),
    valence: getMeanVal(mappedVals.valence),
    instrumentalness: getMeanVal(mappedVals.instrumentalness),
    acousticness: getMeanVal(mappedVals.acousticness),
    liveness: getMeanVal(mappedVals.liveness),
  };

  let modeVals = {
    danceability: getModeVal(mappedVals.danceability),
    energy: getModeVal(mappedVals.energy),
    valence: getModeVal(mappedVals.valence),
    instrumentalness: getModeVal(mappedVals.instrumentalness),
    acousticness: getModeVal(mappedVals.acousticness),
    liveness: getModeVal(mappedVals.liveness),
  };


  let medianData = { data: medianVals, meta: { color: "#ff00ff" } };
  let meanData = { data: meanVals, meta: { color: "#ffff00" } };
  let modeData = { data: modeVals, meta: { color: "#00ffff" } };

  let newData = [meanData, medianData, modeData];


  return newData;
}

function getMedianVal(array) {


  if ((array.length == 1)) {
    return array[0];
  }

  let adjustedArray = array.map((x) => {
    if (x != null) {
      return x;
    }
  }
  );
  adjustedArray.sort();
  if (adjustedArray.length % 2 == 1) {
    let len = adjustedArray.length;
    let pos = Math.ceil(len / 2);
    return adjustedArray[pos];
  } else {
    let len = adjustedArray.length;
    let lower = len / 2;
    let upper = len / 2 + 1;
    return (adjustedArray[lower] + adjustedArray[upper]) / 2;
  }
}

function getMeanVal(array) {
  let sum = 0;
  for (let i = 0; i < array.length; i++) {
    sum += array[i];
  }
  return sum / array.length;
}

function getModeVal(array) {
  let modeCounts = Array.apply(null, Array(20)).map(function (y, i) { return 0; })
  let adjustedArray = array.map((x) => {
    if (x != null) {
      let ceilVal = Math.ceil(x * 10);
      if (x >= ceilVal) {
        return ceilVal + 0.5;
      } else {
        return ceilVal;
      }
    }
  });

  for (let i = 0; i < adjustedArray.length; i++) {
    modeCounts[adjustedArray[i] * 2 - 1]++;

  }

  let maxCount = { index: 0, val: 0 };
  let procModes = [0];
  for (let i = 0; i < modeCounts.length; i++) {
    if (modeCounts[i] >= maxCount.val) {
      if (modeCounts[i] > maxCount.val) {
        procModes = [i];
        maxCount.index = i;
        maxCount.val = modeCounts[i];
      } else {
        procModes.push(i);
      }
    };
  }

  let aveMode = getMeanVal(procModes.map((x) => x / 20));
  return (aveMode);
}

export function getMultiTracksColor(collectiveTrackData) {
  console.log(collectiveTrackData);
  let collectiveAverageData = getMedianChart(collectiveTrackData);
  let aveOfAves = getMedianChart(collectiveAverageData);
  console.log(aveOfAves);
  let aveColor = getTrackColor(collectiveAverageData[0].data);
  return aveColor;

}

export function getDurationStr(duration_ms) {
  var minutes = Math.floor(duration_ms / 60000);
  var seconds = ((duration_ms % 60000) / 1000).toFixed(0);
  return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
}

export function getYear(releaseYear) {
  const yearRegex = /[0-9]{4}/;
  const yearMatch = yearRegex.exec(releaseYear);
  if (yearMatch != null) {
    return yearMatch[0];
  } else {
    return "";
  }
}