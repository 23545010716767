import React, { useEffect, useState } from "react";

import { ReactComponent as CopyIcon } from "../assets/copy.svg";
import { ReactComponent as SearchIcon } from "../assets/search.svg";
import {
  fetchAlbum,
  fetchArtist,
  fetchPlaylist,
  fetchTrackInfo,
} from "../functions/spotify-fetches";
import { useDispatch } from "react-redux";
import { updateMode } from "../redux/searchSlice";
import "../theme/displayParts.css";

const IdTag = (props) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    let style = {};
    // if (props.align != undefined) {
    //   style.alignSelf = props.align;
    // }
    // if (props.justify != undefined) {
    //   style.justifySelf = props.justify;
    // }
    setStyles(style);
  }, [props]);

  const dispatch = useDispatch();

  async function searchForTag() {
    switch (props.type) {
      case "track":
        await fetchTrackInfo(props.tag);
        dispatch(updateMode("track"));
        break;
      case "artist":
        await fetchArtist(props.tag);
        dispatch(updateMode("artist"));
        break;
      case "album":
        await fetchAlbum(props.tag);
        dispatch(updateMode("album"));
        break;
      case "playlist":
        await fetchPlaylist(props.tag);
        dispatch(updateMode("playlist"));
        break;
      default:
        break;
    }
  }

  return (
    <div className="id-tag" style={styles}>
      <p>{props.tag}</p>
      <div>
        <CopyIcon
          onClick={() => {
            navigator.clipboard.writeText(props.tag);
          }}
        />
        {typeof props.lookup == "undefined" || props.lookup ? (
          <SearchIcon onClick={() => searchForTag()} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default IdTag;
