import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RadarChart from "react-svg-radar-chart";

const radarLabels = {
  danceability: "Danceable",
  energy: "Energy",
  valence: "Valence",
  instrumentalness: "Instrumental",
  speechiness: "Speechy",
  acousticness: "Acoustic",
  liveness: "Liveness",
};

const TrackRadarChart = (props) => {
  const deviceMode = useSelector((state) => state.utility.device);
  const [chartMode, setChartMode] = useState("");

  const [chartSize, setChartSize] = useState(300);

  const [hide, setHide] = useState(true);
  useEffect(() => {
    if (typeof props.hide != "undefined") {
      setHide(props.hide);
    }
    if (typeof props.mode == "undefined") {
      setChartMode("");
    } else {
      setChartMode(props.mode);
    }
    handleResize();
  }, [, props]);

  useEffect(() => {
    if (deviceMode == "mobile") {
      setChartSize(getMobileSize());
    } else {
      setChartSize(getDesktopSize());
    }
  });

  function getMobileSize() {
    let scrWidth = window.innerWidth;
    return scrWidth - 100;
  }

  function getDesktopSize() {
    let scrHeight = window.innerHeight;
    if (scrHeight > 900) {
      return 550;
    } else {
      return 400;
    }
  }

  function handleResize() {
    if (typeof props.size != "undefined") {
      setChartSize(props.size);
      return;
    }

    let calcChartSize = 300;
    var heighWidthRatio =
      window.innerHeight.toPrecision(2) /
      (window.innerWidth.toPrecision(2) * 0.8);
    if (window.innerHeight > window.innerWidth) {
      calcChartSize = (window.innerWidth * 90) / 100;
    } else {
      calcChartSize = (window.innerHeight * 60) / 100;
    }

    setChartSize(calcChartSize);
  }

  const topTracks = useSelector((state) => state.user.topTracks);

  return (
    <div className={"radar-chart ".concat(deviceMode)}>
      {typeof props.title == "undefined" ? <></> : <h3>{props.title}</h3>}
      <RadarChart
        captions={
          typeof props.labels == "undefined" ? radarLabels : props.labels
        }
        data={props.radardata}
        size={chartSize}
        options={{
          scales: 4,
          captionMargin: 40,
          zoomDistance: 1.5,
          wrapCaptionAt: 17,
          shapeProps: () => ({
            className: "shape",
            style: {
              fillOpacity: Math.max(150 / props.radardata.length, 30) + "%",
            },
          }),
        }}
      />
      {chartMode == "average" ? (
        <div className="chart-meta">
          <p style={{ background: "#ff00ff80" }}>Mean</p>
          <p style={{ background: "#ffff0080" }}>Median</p>
          <p style={{ background: "#00ffff80" }}>Mode</p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default TrackRadarChart;
